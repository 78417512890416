/* you can write scope scss here */
/*where new home begins*/

[data-aos] {
  @for $i from 1 through 60 {
    body[data-aos-delay='#{$i * 50}'] &,
    &[data-aos][data-aos-delay='#{$i * 50}'] {
      animation-delay: #{$i * 50}ms;

      &.aos-animate {
        animation-delay: #{$i * 50}ms;
      }
    }
  }
}

.hero-banner {
  position: relative;
  overflow: hidden;
}

.hero-banner-text {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg-video {
  position: relative;
  z-index: 1;
}

.big-title {
  font-size: 48px;
  font-weight: bold;
}

.big-sub-title {
  font-size: 24px;
}
@media (min-width: 992px) {
  .big-title {
    font-size: 68px;
    font-weight: bold;
  }

  .big-sub-title {
    font-size: 36px;
  }
}

.section-title {
  line-height: 42px;
}

.card-title {
  max-width: 80px;
}

.card-book {
  border: 4px solid #f0f0f0;
}

.border1 {
  border-bottom: 6px solid #00b7b7;
}
.border2 {
  border-bottom: 6px solid #ff9900;
}
.border3 {
  border-bottom: 6px solid #800080;
}

.color-cfa {
  background-color: #00b7b7;
}
.color-frm {
  background-color: #ff9900;
}
.color-cpa {
  background-color: #800080;
}

.title-cfa {
  border-left: 8px solid #00b7b7;
  padding-left: 20px;
}

.title-frm {
  border-left: 8px solid #ff9900;
  padding-left: 20px;
}

.title-cpa {
  border-left: 8px solid #800080;
  padding-left: 20px;
}

.bg-data {
  background: url(https://resources.pzacademy.com/img/nuxt/kv2.jpg) no-repeat;
  background-size: cover;
}

.number-area {
  border-right: 1px solid rgba(256, 256, 256, 0.25);
}

.color-block {
  display: inline-block;
  width: 35px;
  height: 14px;
  background-color: #00b7b7;
  margin-right: 2px;
}

.avatar {
  display: block;
  width: 24px;
  height: 24px;
  background-color: #00b7b7;
}

.card-cert {
  /*height: 430px;*/
}

.bg-grey-150 {
  background-color: #f2f2f2;
}

.m-title {
  font-size: 26px;
  font-weight: bold;
}

.text-black {
  color: #222;
}

.f-28 {
  font-size: 28px;
}

.f-24 {
  font-size: 24px;
}

.list-point a:hover {
  text-decoration: none;
  cursor: pointer;
  background-color: #f8f8f8;
}
