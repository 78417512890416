/* page sytle.css */

/* 导航功能设置 */

/* .course .toolBar > .nav-item > .nav-link,
.book .toolBar > .nav-item > .nav-link,
.exercise .toolBar > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.5) !important;
} */

.free-book .toolBar ._hidden,
.reader-book .toolBar ._hidden,
.pure-book .toolBar ._hidden,
.mini-book .toolBar ._hidden,
.pure-course .toolBar ._hidden,
.book .toolBar ._hidden,
.favorite .toolBar ._hidden,
.flash-card-landing .toolBar ._hidden,
.exercise .toolBar ._hidden {
  display: none !important;
}

.input-clear {
  position: relative;
}

.input-clear .form-control {
  padding-right: 25px;
}

.input-clear .input-clear-btn {
  display: none;
  position: absolute;
  right: 5px;
  top: 6px;
}

.input-clear .form-control:valid + .input-clear-btn {
  display: block;
}

/*
Setting Page Style
--------------------*/

.setting a.user-photo {
  position: relative;
  overflow: hidden;
}

.setting a.user-photo i {
  display: none;
}

.setting a.user-photo:hover i {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
}

/*
  手机-地区下拉列表
--------------------------- */

.reg-phone {
  position: relative;
  margin-bottom: 10px;
}

/* .reg-phone .btn-outline-secondary {
    border-right: 0 !important;
} */

/* .reg-phone .reg-phone-input {
    text-align: left;
    position: relative;
}

.reg-phone .reg-phone-input::after {
    content: '';
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    bottom: 0;
    width: 45px;
    border-left: solid 1px #ddd;
} */

.reg-phone .reg-phone-list {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  background-color: #fff;
}

.reg-phone .reg-phone-list-cancel {
  background-color: #fff;
  height: 50px;
  padding: 0.5rem 1.25rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  border-top: solid 1px #ddd;
}

.reg-phone .reg-phone-list-group {
  height: 100%;
  overflow-y: auto;
  border: solid 1px #ddd;
  padding-bottom: 59px;
  background-color: #fff;
}

.reg-phone .list-group {
  margin: 0;
}

.reg-phone .list-group-divider {
  background-color: #f7f7f7;
  display: block;
  padding: 0.1rem 1.25rem;
}

@media (min-width: 768px) {
  .reg-phone .reg-phone-list {
    position: absolute;
    width: 100%;
    z-index: 3;
    margin-top: 48px;
  }

  .reg-phone .reg-phone-list-group {
    height: auto;
    max-height: 300px;
    padding-bottom: 0;
  }

  .reg-phone .reg-phone-list-cancel {
    display: none;
  }
}

/* .dashboard-page
================================================== */

@media (min-width: 992px) {
  .pz-active {
    background-color: #fff;
  }

  .pz-active .pz-active-event {
    max-height: 400px;
    position: relative;
    padding-top: 60px;
    padding-bottom: 16px;
  }

  .pz-active .pz-active-event:only-child {
    padding-top: 0px;
    padding-bottom: 0px;
    max-height: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    border-radius: 5px !important;
  }

  .pz-active .pz-active-event .card-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    background-color: #fff;
  }

  .pz-active .pz-active-event:only-child .card-header {
    position: relative;
  }

  .pz-active .pz-active-event > .card-body {
    overflow: auto;
    height: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .pz-active .pz-active-event:only-child > .card-body {
    overflow: visible;
    height: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.pz-active .pz-active-mocks:only-child {
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  text-align: left !important;
  max-width: 100%;
  flex: 0 0 100%;
}

.pz-active .pz-active-mocks:only-child img {
  margin-right: 2rem;
}

.pz-active .pz-active-mocks:only-child .card-body {
  flex: 1 !important;
  margin-bottom: 0 !important;
}

.pz-active .pz-active-mocks:only-child .btn {
  margin-left: 2.5rem;
}

@media (max-width: 991.98px) {
  .pz-active .pz-active-mocks {
    display: flex;
    align-items: center;
    border-radius: 5px !important;
  }

  .pz-active .pz-active-mocks .card-body {
    flex: 1 !important;
  }

  .pz-active .pz-active-mocks img,
  .pz-active .pz-active-mocks:only-child img {
    height: 80px !important;
    margin-right: 1rem !important;
  }

  .pz-active .pz-active-mocks h3,
  .pz-active .pz-active-mocks:only-child h3 {
    font-size: 18px !important;
  }

  .pz-active .pz-active-mocks p,
  .pz-active .pz-active-mocks:only-child p {
    font-size: 14px !important;
  }
}

.cert-course ._unpaid,
.cert-course ._waiting {
  /* -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  opacity: .3;
  position: relative;
  pointer-events: none !important; */
  border: 1px dashed rgba(0, 0, 0, 0.1) !important;
  background-color: rgba(0, 0, 0, 0.01) !important;
}

.cert-course ._unpaid ._opacity {
  opacity: 0.3;
}
.cert-course ._unpaid ._hidden,
.cert-course ._waiting ._hidden {
  display: none !important;
}
.cert-course ._unpaid ._block,
.cert-course ._waiting ._block {
  display: block !important;
  opacity: 1 !important;
}

/* .cert-course ._study-card._disabled:after{
  pointer-events: none !important;
} */

@media (max-width: 992.98px) {
  .cert-course .card-header {
    border: 0 !important;
    padding-bottom: 0;
  }

  .cert-course ._study-card,
  .cert-course ._flash-card {
    background-color: rgba(0, 0, 0, 0.025);
    border-radius: 0.25rem;
  }

  /* .cert-course ._study-card._disabled,
  .cert-course ._flash-card._disabled{
    border:1px dashed rgba(0, 0, 0, 0.25) !important
  } */
}

._flash-card-none {
  border: dashed 1px #eee;
  background-color: #f7f7f7;
  border-radius: 4px;
}

._flash-card-none * {
  display: none !important;
}

.ribbon {
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100px;
  height: 100px;
  text-align: center;
  background-color: transparent;
}

.ribbon-inner {
  position: absolute;
  top: 16px;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 30px;
  padding-right: 20px;
  /* padding-left: 20px; */
  overflow: hidden;
  line-height: 30px;
  color: #212121;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #ffd900;
}

.ribbon-inner .icon {
  font-size: 16px;
}

.ribbon-lg .ribbon-inner {
  height: 38px;
  font-size: 1.286rem;
  line-height: 38px;
}

.ribbon-sm .ribbon-inner {
  height: 26px;
  font-size: 0.858rem;
  line-height: 26px;
}

.ribbon-xs .ribbon-inner {
  height: 20px;
  font-size: 0.75rem;
  line-height: 20px;
}

.ribbon-badge {
  top: -2px;
  left: -2px;
  overflow: hidden;
}

.ribbon-badge .ribbon-inner {
  left: -40px;
  width: 100%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ribbon-badge.ribbon-reverse {
  right: -2px;
  left: auto;
}

.ribbon-badge.ribbon-reverse .ribbon-inner {
  right: -40px;
  left: auto;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-bottom {
  top: auto;
  bottom: -2px;
}

.ribbon-badge.ribbon-bottom .ribbon-inner {
  top: auto;
  bottom: 16px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-bottom.ribbon-reverse .ribbon-inner {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

._flashcard-body img {
  max-width: 100%;
  height: auto;
}

/*  */

.audio-progress {
  width: 180px !important;
  height: 180px !important;
}

/* .audio-progress .progress-circle-left, .audio-progress .progress-circle-right {
  background-color: #009688;
} */

/* course page
================================================== */

.book-lists .card:not(._hover) {
  -webkit-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
  opacity: 0.7;
}

.book-lists .card:not(._hover) .book-icon {
  opacity: 0.4;
}

.book-lists .card:not(._hover) .media-body {
  color: rgba(0, 0, 0, 0.5);
}

.award-progress-circle .book-level {
  position: absolute;
  bottom: 0px;
  right: -13px;
  width: 30px;
  height: 27px;
  font-size: 14px;
}

.award-progress-circle .book-complated {
  position: absolute;
  top: 0px;
  right: -3px;
  border: solid 1px #fff;
  background-color: #fff;
  border-radius: 50%;
}

.progress-circle-percent .book-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  z-index: 1;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  color: rgb(183, 0, 255) !important;
}

.progress-circle-percent .book-icon::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: -1;
  border-radius: 50%;
  background-color: rgb(255, 255, 255, 0.8);
}

.go-on-study {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 100%;
  z-index: 1001;
  display: flex;
  justify-content: center;
  display: inline-block;
  width: 210px;
  left: calc(50% - 105px);
}
.go-on-study * {
  word-break: keep-all;
}

.course-go-btn,
.course-go-text {
  display: none;
}

.card.active .course-go-btn,
.card.active .course-go-text {
  display: block !important;
}

.course-book .course-award {
  display: flex;
  width: 100%;
  color: #fff;
  align-items: center;
}

.course-book .course-award .course-award-icon {
  order: 12;
  margin-left: auto;
}

@media (min-width: 576px) {
  .course-book .course-award .course-award-icon {
    width: 100px;
    height: 100px;
  }

  .course-book .course-award .course-name {
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  .course-book {
    height: 100%;
    position: sticky;
    top: 70px;
    z-index: 1020;
  }

  .course-book .course-award {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .course-book .course-award .course-award-icon {
    order: 0;
    margin-left: 0;
    margin-bottom: 1rem;
  }

  .course-book .course-award .course-name {
    display: none;
  }

  .course-book .course-award .h5 {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .course-book .course-award .course-award-icon {
    width: 120px;
    height: 120px;
  }

  .course-book .course-award .h5 {
    font-size: 2rem;
  }
}

@media (max-width: 991.98px) {
  .course-book .course-award {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .course-book .embed-responsive-3by4::before {
    padding-top: 32.8571428571%;
  }
}

.study-tab .nav-link {
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: #dfe2e9;
}
.study-tab .nav-link.active {
  border: 0;
  background-color: #fff;
  font-weight: bold;
  color: inherit;
}

/* filter */

.pz-filter:not(.show) + .modal-content {
  display: block;
  opacity: 1;
}

.pz-filter.show + .modal-content {
  display: none;
  opacity: 0;
}

/* flash card page
================================================== */

.flash-card-modal {
  min-width: calc(100% - 0px);
  height: calc(100% - 0px);
  margin: 0 auto;
}

.flash-card-modal .modal-content {
  height: 100%;
  padding: 1rem;
  /* background-color: #f00 !important; */
}

.flash-card-detail.modal-open .modal-backdrop,
.flash-card-favorite.modal-open .modal-backdrop {
  background-color: #eff1f4;
  opacity: 1;
}

.flash-card-modal .modal-content > .embed-responsive {
  perspective: 1000px;
  position: relative;
  overflow: visible;
  max-width: 500px;
  height: 100%;
  margin: auto;
}

.flash-card-modal footer {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 767.98px) and (min-height: 767.98px) {
  .flash-card-modal .modal-content > .embed-responsive {
    height: auto;
  }

  .flash-card-modal .modal-content > .embed-responsive:before {
    padding-top: 133.3333333333%;
  }
}

/* @media (min-width:1200px) and (min-height:768px){
  .flash-card-modal .modal-content > .embed-responsive{
    height: auto;
  }
  .flash-card-modal .modal-content > .embed-responsive:before{
  padding-top:133.3333333333%;
}
} */

.embed-responsive-item.front,
.embed-responsive-item.back {
  border-radius: 0.5rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
}

.embed-responsive-item.front {
  /* background: linear-gradient(135deg, #1a9be6, #1a57e6); */
  background: #fff;
  overflow: hidden;
}

.embed-responsive-item.back {
  background: #fff;
  transform: rotateY(-180deg);
}

.embed-responsive.on .embed-responsive-item.front {
  transform: rotateY(180deg);
}

.embed-responsive.on .embed-responsive-item.back {
  transform: rotateY(0deg);
}

.flash-card-modal .btn-bookmark {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 0.25rem;
}

/* .embed-responsive-item.filter{
  display: none;
}
.embed-responsive-item.filter.on{
  display: flex;
} */
.flashcard-overlay .list-group-item .icon-check-box {
  display: none;
}

.flashcard-overlay .list-group-item.on .icon-check-box-blank {
  display: none;
}

.flashcard-overlay .list-group-item.on .icon-check-box {
  display: block;
}

.flashcard-overlay {
  display: none;
}

.flashcard-overlay.on {
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}

.flashcard-overlay .flashcard-backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.flashcard-overlay .flashcard-overlay-content {
  display: flex;
  flex-direction: column;
  position: fixed;
}

.flashcard-overlay .flashcard-overlay-content.center {
  max-height: 100%;
  max-width: 500px;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.flashcard-overlay .flashcard-overlay-content.bottom {
  height: 70%;
  bottom: 0;
  left: 0;
  right: 0;
}

._answer {
  position: relative;
  height: 26px;
  width: 100px;
  text-align: left;
  padding-left: 1rem !important;
}

._answer::before,
._answer::after {
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 15px;
}

._answer::before {
  border-color: transparent transparent #fff transparent;
}

._answer::after {
  border-color: transparent #fff transparent transparent;
}

/* book page
================================================== */

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .pz-sidebar-book {
      position: -webkit-sticky;
      position: sticky;
      top: 90px;
      /* z-index: 1000; */
      height: calc(100vh - 150px);
    }
  }
}

/*
  章节升级动画
  ----------- */
.course-to-up .course-award-icon-wrapper {
  position: relative;
  width: 120px;
  height: 120px;
}

.course-to-up .course-award-icon-wrapper .course-award-icon._v1-0,
.course-to-up .course-award-icon-wrapper .course-award-icon._v2-0,
.course-to-up .course-award-icon-wrapper .course-award-icon._v3-0 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.course-to-up .course-award-icon-wrapper .course-award-animated {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.book-to-master-exercise {
  position: relative;
  width: 100%;
  max-width: 50%;
}

.book-to-master-exercise .book-level {
  /* position: absolute;
    left: 40px;
    top: -45px; */
  width: 50px;
  height: 50px;
}

.book-to-master-exercise .book-to-master-bg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.2);
}

.book-to-master {
  position: relative;
  margin-top: 4rem;
}

.book-to-master .book-to-master-bg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -25%) scale(1.2);
}

.book-to-master .book-icon {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: relative;
  /* border: 6px solid rgba(255, 255, 255, .2); */
}

.book-to-master .book-level {
  position: absolute;
  left: 40px;
  top: -45px;
  width: 80px;
  height: 80px;
}

._17ac5n34 {
  align-items: stretch !important;
  border-width: 0px !important;
  border-style: solid !important;
  display: flex !important;
  flex-direction: column !important;
  margin: 0px !important;
  padding: 0px !important;
  z-index: 0 !important;
  min-height: 0px !important;
  min-width: 0px !important;
  position: absolute !important;
  top: 28px !important;
  right: 0px !important;
}

._176g09a4 {
  align-items: stretch !important;
  border-width: 0px !important;
  border-style: solid !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  margin: 0px !important;
  padding: 0px !important;
  z-index: 0 !important;
  min-height: 0px !important;
  min-width: 0px !important;
  position: absolute !important;
  top: -17px !important;
  left: 60px !important;
}

@keyframes award-zoom-animated {
  0% {
    -webkit-transform: scale(3) rotate(0deg);
    -ms-transform: scale(3) rotate(0deg);
    transform: scale(3) rotate(0deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}

.award-zoom {
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: award-zoom-animated;
  animation-name: award-zoom-animated;
}

@keyframes keyframe_1v0tnnm {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    -ms-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
    opacity: 1;
  }

  3% {
    -webkit-transform: translate(-50%, -50%) scale(1) rotate(15deg);
    -ms-transform: translate(-50%, -50%) scale(1) rotate(15deg);
    transform: translate(-50%, -50%) scale(1) rotate(15deg);
    opacity: 1;
  }

  6% {
    -webkit-transform: translate(-50%, -50%) scale(1) rotate(15deg);
    -ms-transform: translate(-50%, -50%) scale(1) rotate(15deg);
    transform: translate(-50%, -50%) scale(1) rotate(15deg);
    opacity: 1;
  }

  9% {
    -webkit-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    -ms-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    -ms-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
    opacity: 1;
  }
}

._1w7dtjpw {
  -webkit-animation-duration: 4s !important;
  animation-duration: 4s !important;
  -webkit-animation-iteration-count: infinite !important;
  animation-iteration-count: infinite !important;
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important;
  -webkit-animation-fill-mode: forwards !important;
  animation-fill-mode: forwards !important;
  opacity: 0 !important;
  pointer-events: none !important;
  -webkit-animation-name: keyframe_1v0tnnm !important;
  animation-name: keyframe_1v0tnnm !important;
}

.alert .arrow {
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  bottom: -8px;
  right: 26px;
}

.alert .arrow::after,
.alert .arrow::before {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.alert .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #b8daff;
}

.alert .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #cce5ff;
}

.book-up-alert {
  position: absolute;
  width: 320px;
  top: 70px;
  left: 30px;
  transform: translate(-50%, 0);
}

.book-up-alert .alert {
  background-color: #8e24aa;
  color: #fff;
}

.book-up-alert .alert::after,
.book-up-alert .alert::before {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.book-up-alert .alert::before {
  top: -15px;
  border-width: 0 20px 20px;
  border-bottom-color: #7b1fa2;
}

.book-up-alert .alert::after {
  top: -14px;
  border-width: 0 20px 20px;
  border-bottom-color: #8e24aa;
}

._ay4wjb {
  position: absolute !important;
}

.star-animation-1 {
  -webkit-animation-duration: 4s !important;
  animation-duration: 4s !important;
  -webkit-animation-iteration-count: infinite !important;
  animation-iteration-count: infinite !important;
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important;
  -webkit-animation-fill-mode: forwards !important;
  animation-fill-mode: forwards !important;
  opacity: 0 !important;
  pointer-events: none !important;
  -webkit-animation-name: keyframe_1v0tnnm !important;
  animation-name: keyframe_1v0tnnm !important;
}

@keyframes keyframe_1v0tnnm {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    -ms-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
    opacity: 1;
  }

  3% {
    -webkit-transform: translate(-50%, -50%) scale(1) rotate(15deg);
    -ms-transform: translate(-50%, -50%) scale(1) rotate(15deg);
    transform: translate(-50%, -50%) scale(1) rotate(15deg);
    opacity: 1;
  }

  6% {
    -webkit-transform: translate(-50%, -50%) scale(1) rotate(15deg);
    -ms-transform: translate(-50%, -50%) scale(1) rotate(15deg);
    transform: translate(-50%, -50%) scale(1) rotate(15deg);
    opacity: 1;
  }

  9% {
    -webkit-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    -ms-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    -ms-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
    opacity: 1;
  }
}

.star-animation-2 {
  -webkit-animation-duration: 4s !important;
  animation-duration: 4s !important;
  -webkit-animation-iteration-count: infinite !important;
  animation-iteration-count: infinite !important;
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important;
  -webkit-animation-fill-mode: forwards !important;
  animation-fill-mode: forwards !important;
  opacity: 0 !important;
  pointer-events: none !important;
  -webkit-animation-name: keyframe_ywx4ua !important;
  animation-name: keyframe_ywx4ua !important;
}

@keyframes keyframe_ywx4ua {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    -ms-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
    opacity: 1;
  }

  3% {
    -webkit-transform: translate(-50%, -50%) scale(1) rotate(-15deg);
    -ms-transform: translate(-50%, -50%) scale(1) rotate(-15deg);
    transform: translate(-50%, -50%) scale(1) rotate(-15deg);
    opacity: 1;
  }

  6% {
    -webkit-transform: translate(-50%, -50%) scale(1) rotate(-15deg);
    -ms-transform: translate(-50%, -50%) scale(1) rotate(-15deg);
    transform: translate(-50%, -50%) scale(1) rotate(-15deg);
    opacity: 1;
  }

  9% {
    -webkit-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    -ms-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    -ms-transform: translate(-50%, -50%) scale(0) rotate(0deg);
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
    opacity: 1;
  }
}

/*  */

.btn-floating {
  position: fixed;
  right: 3%;
  bottom: 8%;
  z-index: 1000;
  /* width: 240px; */
}

.book-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.book-page-doc {
  position: fixed;
  right: 3%;
  bottom: 8%;
  /* background-color: #f00; */
  z-index: 1001;
  width: 240px;
}

.book-page-doc .alert {
  position: absolute;
  bottom: 72px;
  right: 0px;
}

.book-page-doc-btn {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 72px;
  height: 72px;
  /* z-index: 1000; */
}

.reading-summary {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.reading-summary .reading-menu {
  overflow-y: auto;
}

.reading-summary .sticky-bottom {
  box-shadow: 0px -16px 24px -24px rgba(0, 0, 0, 0.64) !important;
  background-color: #edeff0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.book-page .exit {
  display: none !important;
}

.book-doc-download {
  position: fixed;
  top: 170px;
}

/* 控制book页面购买按钮 */

.reading-lists .card .btn-buy {
  display: none;
}

.reading-lists .card:not(._hover) * {
  color: rgba(0, 0, 0, 0.3) !important;
  pointer-events: none !important;
}

.reading-lists .card:not(._hover) .btn-buy {
  display: block !important;
  pointer-events: auto !important;
  color: #fff !important;
}

.reading-lists .card:not(._hover) .active {
  border-color: rgba(0, 0, 0, 0.3) !important;
}

/* 控制book页面购买按钮 */

/* mini book page
================================================== */

.mini-book-doc-download {
  position: fixed;
  top: 115px;
}

/* free book page
================================================== */

.free-course-page ._free-2 ._free-2 {
  display: block !important;
}

.free-course-page ._free-1 ._free-1 {
  display: block !important;
}

.free-book-doc-download {
  position: fixed;
  top: 115px;
}

/* course-exercise page
================================================== */

.pzChart-wrap {
  position: relative;
}

.pzChart-text {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 5%;
  left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: baseline;
}

/* Course bookmarks */

@media (max-width: 991.98px) {
  .course-bookmarks-menu.show,
  .course-notes-menu.show {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    width: auto;
    height: 100%;
    padding-top: 70px;
    z-index: 1000;
    background: #fff;
  }

  .course-bookmarks-menu.collapsing,
  .course-notes-menu.collapsing {
    position: fixed;
    background: #fff;
    height: 100%;
    overflow: hidden;
    transition: opacity 0.35s ease;
    transition: none;
  }
}

@media (min-width: 992px) {
  .course-bookmarks-menu.collapse,
  .course-notes-menu.collapse {
    display: block !important;
  }

  .course-bookmarks-menu,
  .course-notes-menu {
    height: 100%;
    position: sticky;
    top: 70px;
    z-index: 1020;
  }
}

.dropdown-user {
  min-width: 240px;
}

@media (min-width: 992px) {
  .dropdown-user {
    min-width: 320px;
  }
}

/* .dropdown-messages {
  min-width: 350px;
  padding-bottom: 0;
}

.dropdown-messages-header {
  display: flex;
  justify-content: space-between;
  padding: .5rem 1.25rem;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.dropdown-messages-footer {
  display: flex;
  justify-content: space-between;
  padding: .75rem 1.25rem;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, .1);
  background-color: rgba(0, 0, 0, .035);
  color: #999;
}

.dropdown-messages-body {
  max-height: 300px;
  overflow-y: auto;
} */

/* 做题汇总页 */

.practice-overview {
  width: 320px;
}

.buliding-overview {
  display: flex;
  margin-top: 1.5rem;
}

.buliding-overview._v0 ._t4,
.buliding-overview._v1 ._t4,
.buliding-overview._v1 ._t1,
.buliding-overview._v2 ._t1,
.buliding-overview._v3 ._t1,
.buliding-overview._v3 ._t2,
.buliding-overview._v4 ._t1,
.buliding-overview._v4 ._t2,
.buliding-overview._v4 ._t3 {
  display: none;
}

.buliding-group {
  display: flex;
  flex-direction: column;
  position: relative;
}

._t {
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
}

._t4 {
  bottom: 200px !important;
}

._t3 {
  bottom: 160px !important;
}

._t2 {
  bottom: 94px !important;
}

._t1 {
  bottom: 5px !important;
}

.buliding-line {
  height: 2px !important;
  width: 48px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
  margin-bottom: 4px !important;
  background-color: rgba(255, 255, 255, 0.64) !important;
}

.buliding-text {
  display: flex !important;
  flex-direction: column !important;
  width: 200px !important;
  text-align: left !important;
}

/*
 * Note Layout
 */

.note-landing-menu .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.note-landing-menu .nav-link:hover {
  color: rgba(0, 0, 0, 0.8);
}
.note-landing-menu .nav-link.active {
  color: #17b3a3;
}

.noteCard .embed-responsive-item {
  z-index: 2;
}

.noteCard .course-bg {
  position: absolute;
  top: 50px;
  right: -120px;
  height: 300px;
  width: 200px;
  z-index: 0;
  transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  -o-transform: rotate(30deg);
}

.noteCard .noteNumber {
  position: absolute;
  right: 3%;
  bottom: 5%;
  z-index: 3;
  color: rgba(255, 255, 255, 1);
  font-weight: 300;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
}
.noteCard-name {
  font-size: 18px;
  font-weight: 600;
}

@media (min-width: 768px) and (max-width: 918.98px) {
  .noteCard-name {
    font-size: 20px;
  }
}
@media (min-width: 1560px) {
  .noteCard-name {
    font-size: 22px;
    font-weight: 500;
  }
}

@media (min-width: 1840px) {
  .noteCard-name {
    font-size: 25px;
  }
}

.noteSidebar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.noteSidebar-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.noteSidebar-menu {
  overflow-y: auto;
  height: 100%;
}

.noteSidebar-menu .list-group-item {
  border: 0;
  padding-left: 1.5rem;
  background-color: transparent;
}

.noteSidebar-menu .list-group-item-action:hover,
.noteSidebar-menu .list-group-item-action:focus {
  background-color: rgba(0, 0, 0, 0.05);
}

.noteSidebar-menuBook .list-group-item {
  color: #000 !important;
  background-color: transparent;
  border: 0;
  font-size: 15px;
}
.noteSidebar-menuBook .list-group-item:hover {
  background-color: #fff;
}

.noteSidebar-menuBook .list-group-item.collapsed {
  color: rgb(0, 0, 0, 0.6) !important;
}

.noteSidebar-menuReading {
  position: relative;
}

.noteSidebar-menuReading:after {
  content: '';
  position: absolute;
  left: 1.75rem;
  top: 1rem;
  bottom: 1rem;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.noteSidebar-menuReading .list-group-item {
  color: rgb(0, 0, 0, 0.6) !important;
  padding-left: 2.5rem;
  font-size: 13px;
}

.noteSidebar-menuReading .list-group-item.active {
  background-color: transparent;
  color: #000 !important;
  position: relative;
}

.noteSidebar-menuReading .list-group-item.active:hover,
.noteSidebar-menuReading .list-group-item.active:focus {
  background-color: rgba(255, 255, 255, 1);
}

.noteSidebar-menuReading .list-group-item.active:after {
  content: '';
  position: absolute;
  left: calc(1.75rem - 1px);
  top: 0.75rem;
  bottom: 0.75rem;
  width: 4px;
  background-color: #000;
  z-index: 2;
}

._note {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.note-box {
  margin-bottom: 4rem;
}

.note-box header {
  border-bottom: solid 1px #eee;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
}

.note-box main {
  margin-bottom: 2.5rem;
}
.note-box footer {
  margin-top: 2rem;
}

.noteMain .noteMain-reading:last-child .media:last-child .media-body {
  border: 0 !important;
}

.noteMain .read-more.text-dark {
  text-decoration: underline;
}

.noteMain .read-more.text-dark:hover {
  text-decoration: none;
}

.noteMain-intro.collapse,
.noteMain-intro.collapse.show .noteMain-detail {
  display: block;
}

.noteMain-intro.collapse .noteMain-detail,
.noteMain-intro.collapse.show .noteMain-summary,
.noteMain-intro.collapse.show .noteRead-moreLink {
  display: none;
}

.noteMain-intro.collapsing .noteMain-summary,
.noteMain-intro.collapsing .noteRead-moreLink {
  display: none;
  opacity: 0;
}

.noteRead-moreBtn ._more,
.noteRead-moreBtn.collapsed ._less {
  display: none;
}

.noteRead-moreBtn ._less,
.noteRead-moreBtn.collapsed ._more {
  display: block;
}

.noteDownload {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  /* background-color: #f00; */
  z-index: 1000;
  width: 240px;
}

.noteDownload .alert {
  position: absolute;
  bottom: 72px;
  right: 0px;
}

.noteDownload-btn {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 72px;
  height: 72px;
  /* z-index: 1000; */
}

/* referral page
--------------------------------------------------------------------*/

.login-rodio .btn-outline-light {
  width: 50% !important;
  height: 180px !important;
}

.big-rodio {
  display: inline-block;
}

.big-rodio .btn-outline-light {
  /* margin: 3rem 1rem; */
  border-radius: 0.25rem !important;
  padding: 2rem 2rem;
  /* width: 200px; */
  height: 150px;
  overflow: hidden;
  border: 2px solid #ccc;
}

.big-rodio .btn-outline-primary {
  margin: 0.5rem 1.5% !important;
  border-radius: 0.25rem !important;
  overflow: hidden;
  border: 2px solid #ccc;
  width: 30%;
}

.big-rodio .btn:not(:disabled):not(.disabled):active,
.big-rodio .btn:not(:disabled):not(.disabled):active,
.big-rodio .btn:not(:disabled):not(.disabled).active,
.big-rodio .show > .btn.dropdown-toggle {
  color: #343a40 !important;
  background-color: #fff !important;
  border-width: 2px;
  border-color: #17b3a3;
}

.big-rodio .btn-selected {
  display: none;
  color: #17b3a3;
  font-size: 32px;
}

.big-rodio .btn:not(:disabled):not(.disabled):active .btn-selected,
.big-rodio .btn:not(:disabled):not(.disabled):active .btn-selected,
.big-rodio .btn:not(:disabled):not(.disabled).active .btn-selected {
  display: block;
}

/* .audioLayer-card {
    background-color: #fff;
    min-width: 300px;
    max-width: 450px;
    padding: 20px;
    border-radius: 5px;
}

.audioLayer .audio-info {
    width: 122px;
    height: 122px;
    position: relative;
    margin: 20px auto;
}

.audioLayer .audio-info .progress-circle i {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 50%;
    top: 25%;
    margin-left: -15px;
    z-index: 100;
}

.audioLayer .audio-info .progress-circle label {
    width: 80%;
    display: inline-block;
    position: absolute;
    left: 10%;
    top: 15%;
    text-align: center;
    z-index: 100;
    font-size: 12px;
    color: #999;
}

.audioLayer .audio-info .progress-circle {
    font-size: 20px;
    margin: 10px auto;
    position: relative;
    padding: 0;
    width: 120px;
    height: 120px;
    background-color: #009688;
    border-radius: 50%;
    line-height: 120px;
}

.audioLayer .audio-info .progress-circle:after {
    border: none;
    position: absolute;
    top: 10px;
    left: 10px;
    text-align: center;
    display: block;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #fff;
    content: " ";
}

.audioLayer .audio-info .left-half-clipper {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    position: absolute;
    clip: rect(0, 120px, 120px, 60px);
}

.audioLayer .audio-info .progress-circle.over50 .left-half-clipper {
    clip: rect(auto, auto, auto, auto);
}

.audioLayer .audio-info .value-bar {
    position: absolute;
    clip: rect(0, 60px, 120px, 0);
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 10px solid #eee;
    box-sizing: border-box;
}

.audioLayer .audio-info .progress-circle.over50 .first50-bar {
    position: absolute;
    clip: rect(0, 120px, 120px, 60px);
    background-color: #eee;
    border-radius: 50%;
    width: 120px;
    height: 120px;
}

.audioLayer .audio-info .progress-circle:not(.over50) .first50-bar {
    display: none;
}

.audioLayer .played-progress {
    color: #fff
}

.audioLayer .loaded-progress {
    color: #fff
}

.bg-audio {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    border: solid 2px rgba(0, 0, 0, .1);
    background-color: rgba(0, 0, 0, .05);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65%;
} */

/* teachers */
.teacher-header {
  /* background-image: url('../../img/to-delete/teacher-header.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.teacher-photo {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
/* .teacher-photo._lsk {
  background-image: url('../../img/teacher-to-delete/lsk.jpg');
}
.teacher-photo._hx {
  background-image: url('../../img/teacher-to-delete/hx.jpg');
}
.teacher-photo._xh {
  background-image: url('../../img/teacher-to-delete/xh.jpg');
}
.teacher-photo._xz {
  background-image: url('../../img/teacher-to-delete/xz.jpg');
}
.teacher-photo._zsm {
  background-image: url('../../img/teacher-to-delete/zsm.jpg');
} */

/* =============
    首页面
============= */

.card-store-m {
  height: 200px;
  /* background-image: url('../../img/store-m_2x.png'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #363d44;
}
.card-1-m {
  height: 150px;
  /* background-image: url('../../img/c1-m_2x.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-2-m {
  height: 150px;
  /* background-image: url('../../img/c5-m_2x.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-3-m {
  height: 150px;
  /* background-image: url('../../img/c3-m_2x.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-4-m {
  height: 150px;
  /* background-image: url('../../img/c4-m_2x.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slide-m {
  height: 160px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-item {
  background-position: center;
  /*height: 350px;*/
  background-size: cover;
}
@media (min-width: 1200px) {
/*  .carousel-item {
    height: 500px;
  }*/
}

/* .slide-0 {
  background-image: url(http://resources.pzacademy.com/img/nuxt/home-1.png);
}

.slide-1 {
  background-image: url(http://resources.pzacademy.com/img/nuxt/home-2.png);
}

.slide-2 {
  background-image: url(http://resources.pzacademy.com/img/nuxt/home-3.png);
}

.slide-3 {
  background-image: url(http://resources.pzacademy.com/img/nuxt/home-4.png);
}

.slide-4 {
  background-image: url(http://resources.pzacademy.com/img/nuxt/home-5.png);
} */

.feature-card-home {
  display: block;
  height: 400px;
  border: solid 1px #f5f5f5;
  text-align: center;
  padding: 20px;
  background-position: center;
  background-repeat: no-repeat;
}
.sublink-card {
  text-align: center;
  display: block;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
}

.sublink-card a {
  display: block;
  height: 200px;
}

.card-1 {
  background-color: #cde4ea;
}

.card-2 {
  background-color: #303030;
}

.card-3 {
  background-color: #8f82bc;
}

.card-4 {
  background-color: #eaedf1;
}

.card-5 {
  background-color: #3c3c3c;
}

.card-6 {
  background-color: #e1eeea;
}

.card-7 {
  background-color: #f6f0e1;
}

.card-8 {
  background-color: #208c7e;
}

/* Reader page */

.new-tag {
  top: -5px;
  left: -5px;
  position: absolute;
}

.reader-label {
  position: relative;
  padding: 5px 10px;
  margin: auto;
  display: inline-block;
  color: #999;
  margin-bottom: 20px;
}

.reader-label:before,
.reader-label:after {
  content: '';
  position: absolute;
  top: 18px;
  width: 80px;
  height: 1px;
}

.reader-label:before {
  left: -80px;
  background-image: linear-gradient(45deg, rgba(67, 67, 69, 0) 0%, rgba(67, 67, 69, 0.3) 100%);
}

.reader-label:after {
  right: -80px;
  background-image: linear-gradient(-45deg, rgba(67, 67, 69, 0) 0%, rgba(67, 67, 69, 0.3) 100%);
}

.readers-avatar .avatar-more {
  width: 31px;
  height: 31px;
  padding: 2px;
  border: solid 1px rgba(10, 10, 10, 0.05);
  /*background-color: rgba(0, 0, 0, .02);*/
  display: inline-block;
  border-radius: 50%;
  color: #ccc;
  font-family: FontAwesome;
}

.readers-avatar .avatar-more:before {
  content: '\f141';
  position: relative;
  top: 2px;
}

/* Transcript */

.transcript {
  margin-top: 50px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border: 0;
  padding: 15px;
  background-color: #ffffff;
  /*background-image: url("../../img/church-on-sunday.svg");*/
}

.transcript-dashed {
  border: dashed 1px #424959;
  padding: 8px;
}

.transcript-line {
  border: solid 4px #424959;
  padding: 2px;
  background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23e2e9f0' fill-opacity='0.41' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.transcript-lt,
.transcript-rt,
.transcript-lb,
.transcript-rb {
  width: 116px;
  height: 117px;
  position: absolute;
}

.transcript-lt {
  left: 0px;
  top: 0px;
  background: url('../../img/lt.svg') no-repeat;
}

.transcript-rt {
  right: 0px;
  top: 0px;
  background: url('../../img/rt.svg') no-repeat;
}

.transcript-lb {
  left: 0px;
  bottom: 0px;
  background: url('../../img/lb.svg') no-repeat;
}

.transcript-rb {
  right: 0px;
  bottom: 0px;
  background: url('../../img/rb.svg') no-repeat;
}

.transcript-logo {
  width: 64px;
  height: 48px;
  margin: 30px auto;
  background: url('../../img/pz_icon_big.svg') no-repeat;
  background-size: cover;
}

.transcript-name {
  height: 1px;
  width: 60%;
  margin: auto;
  position: relative;
  background-image: -webkit-linear-gradient(
    bottom left,
    rgba(67, 67, 69, 0) 0%,
    #373739 28%,
    #434345 76%,
    rgba(68, 68, 70, 0) 100%
  );
}

.transcript-name span {
  position: absolute;
  top: -15px;
  left: 50%;
  font-size: 18px;
  background: #424959;
  color: #fff;
  text-align: center;
  height: 29px;
  line-height: 29px;
  width: 160px;
  margin: 0;
  margin-left: -80px;
  padding: 0;
  display: inline-block;
}

.transcript-name span:before,
.transcript-name span:after {
  content: '';
  position: absolute;
  display: block;
  top: 0px;
  border: 15px solid #424959;
}

.transcript-name span:before {
  left: -20px;
  border-left-width: 15px;
  border-left-color: transparent;
}

.transcript-name span:after {
  right: -20px;
  border-right-width: 15px;
  border-right-color: transparent;
}

.transcript-title {
  font-size: 40px;
  text-align: center;
  position: relative;
}

.transcript-title-line {
  height: 2px;
  width: 60%;
  margin: 30px auto 20px;
  display: block;
  clear: both;
  position: relative;
  background-image: -webkit-linear-gradient(
    bottom left,
    rgba(67, 67, 69, 0) 0%,
    #373739 28%,
    #434345 76%,
    rgba(68, 68, 70, 0) 100%
  );
}

.transcript-total {
  width: 60%;
  margin: 30px auto 50px;
  text-align: center;
}

.transcript-total-value {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.transcript-total-value span {
  font-size: 30px;
  color: #d0d8de;
}

.transcript-total-score {
  width: 150px;
  height: 184px;
  margin: 30px auto;
  background-image: url('../../img/total-score-bg.png');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  position: relative;
  text-align: center;
  color: #fff;
}

.transcript-total-score h1 {
  font-size: 40px;
  font-weight: 500;
  margin: 45px auto;
  padding: 0;
  display: inline-block;
}

.transcript-total-score h1:after {
  content: '%';
  font-size: 30%;
  position: absolute;
  top: 75px;
  margin-left: 3px;
}

.transcript-average {
  width: 150px;
  height: 184px;
  margin: 30px auto;
  background-image: url('../../img/average-bg.png');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  position: relative;
  text-align: center;
  color: #fff;
}

.transcript-average h1 {
  font-size: 40px;
  font-weight: 500;
  margin: 45px auto;
  padding: 0;
  display: inline-block;
}

.transcript-average h1:after {
  content: '%';
  font-size: 30%;
  position: absolute;
  top: 75px;
  margin-left: 3px;
}

.mock-pass {
  width: 150px;
  height: 184px;
  margin: 30px auto;
  background-image: url('../../img/mock-emax-bg.png');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
}

.transcript-total-text {
  font-size: 2.2rem;
  color: #0097ca;
}

.transcript-label {
  position: relative;
  padding: 5px 10px;
  margin: auto;
  display: inline-block;
  font-size: 18px;
}

.transcript-label:before,
.transcript-label:after {
  content: '';
  position: absolute;
  top: 16px;
  width: 100px;
  height: 1px;
}

.transcript-label:before {
  left: -100px;
  background-image: linear-gradient(45deg, rgba(67, 67, 69, 0) 0%, #373739 100%);
}

.transcript-label:after {
  right: -100px;
  background-image: linear-gradient(-45deg, rgba(67, 67, 69, 0) 0%, #373739 100%);
}

.transcript-book {
  text-align: center;
}

.transcript-book .alert {
  width: 78%;
  margin: auto;
}

.transcript-book-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.transcript-book-list .col {
  /*display: flex;
  align-items: flex-start;*/
  margin-bottom: 30px;
  text-align: center;
  list-style: none;
}

.transcript-book-list .col > div {
  margin: auto;
  display: inline-block;
}

.transcript-report {
  text-align: center;
}

.transcript-report h3 {
  color: #0097ca;
  margin: 20px auto;
}

.transcript-report-bar {
  position: relative;
  width: 350px;
  height: 200px;
  border-left: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  margin: 30px auto 50px;
}

.scale {
  position: absolute;
  left: -30px;
  top: 0;
  bottom: 0;
  width: 30px;
  text-align: right;
}

.scale-0,
.scale-20,
.scale-40,
.scale-60,
.scale-80,
.scale-100 {
  position: absolute;
  right: 10px;
  width: 50px;
  color: #ccc;
  font-size: 8px;
  /*border-bottom: solid 1px #ddd;*/
}

.scale-0 {
  bottom: -10px;
}

.scale-20 {
  bottom: 10%;
}

.scale-40 {
  bottom: 30%;
}

.scale-60 {
  bottom: 50%;
}

.scale-80 {
  bottom: 70%;
}

.scale-100 {
  bottom: 90%;
}

.report-1,
.report-2 {
  width: 100px;
  position: absolute;
  bottom: 0;
  border-radius: 5px 5px 0 0;
}

.report-1,
.report-2 {
  width: 100px;
  position: absolute;
  bottom: 0;
  border-radius: 5px 5px 0 0;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  /* background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent); */
  -webkit-background-size: 1rem 1rem;
  background-size: 1rem 1rem;
}

.report-1 .tooltip,
.report-2 .tooltip {
  top: -35px;
  left: 50%;
  margin-left: -45px;
  white-space: nowrap;
}

.report-1:hover .tooltip,
.report-2:hover .tooltip {
  opacity: 1;
}

.report-1 label,
.report-2 label {
  position: absolute;
  bottom: -30px;
  left: 0;
  text-align: center;
  width: 100%;
  font-weight: normal;
}

.report-1 b,
.report-2 b {
  position: absolute;
  top: -30px;
  font-size: 24px;
  left: 0;
  text-align: center;
  width: 100%;
  font-weight: normal;
}

.report-1 {
  height: 50%;
  left: 50px;
  background-color: #0288d1;
}

.report-2 {
  height: 50%;
  right: 50px;
  background-color: #009688;
}

.transcript-share {
  text-align: center;
}

.transcript-share-qrcode {
  width: 150px;
  height: 150px;
  margin: 20px auto;
  object-fit: cover;
  background-color: #eee;
}

.transcript-cfa-1 .value-bar {
  border-color: #71fada;
}

.transcript-cfa-1 .progress-circle.over50 .first50-bar {
  background-color: #71fada;
}

.transcript-cfa-2 .value-bar {
  border-color: #42f3e4;
}

.transcript-cfa-2 .progress-circle.over50 .first50-bar {
  background-color: #42f3e4;
}

.transcript-cfa-3 .value-bar {
  border-color: #10dce0;
}

.transcript-cfa-3 .progress-circle.over50 .first50-bar {
  background-color: #10dce0;
}

.transcript-frm-1 .value-bar {
  border-color: #ffdb7a;
}

.transcript-frm-1 .progress-circle.over50 .first50-bar {
  background-color: #ffdb7a;
}

.transcript-frm-2 .value-bar {
  border-color: #ffaaaf;
}

.transcript-frm-2 .progress-circle.over50 .first50-bar {
  background-color: #ffaaaf;
}

.paypal-card {
  border-radius: 6px;
}

.paypal-title {
  display: inline-block;
}

.paypal-result-icon {
  float: left;
}

.paypal-result-title {
  float: left;
  margin-left: 10px;
}

.paypal-result p {
  font-size: 16px;
}

.paypal-result {
  margin: 40px;
}

.handl-exam-hide-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 990;
  filter: alpha(opacity=60);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
  top: 0px;
  left: 0px;
  text-align: center;
}

.handl-exam-hide-layer .loading {
  background: url('../../img/loadingicn.gif') no-repeat;
  display: inline-block;
  margin-top: 118px;
  height: 291px;
  width: 441px;
  opacity: 1;
}

.mall-tab .nav-link {
  color: #76838f;
  /* font-weight: 400; */
}
.mall-tab .nav-link:hover {
  color: #17b3a3;
}
.mall-tab .nav-link.active {
  color: #17b3a3;
  /* font-weight: 700; */
  pointer-events: none;
}

/*  video-timeline */
.video-timeline1,
.video-timeline2 {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  list-style: none;
  padding: 0;
  position: relative;
  font-size: 13px;
}

.video-timeline1 > li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 1rem;
}
.video-timeline1 > li::before {
  content: '';
  border-left: 2px solid #eee;
  height: calc(100% + 15px);
  left: 0;
  top: -10px;
  bottom: -5px;
  position: absolute;
}
.video-timeline1 > li::after {
  content: '├──';
  left: -3px;
  top: 0;
  position: absolute;
  color: #eee;
}
.video-timeline1 > li:last-child::before {
  content: '';
  border-left: 1px solid #fff;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}
.video-timeline1 > li:last-child::after {
  content: '└──';
  left: -3px;
  top: 0;
  position: absolute;
  color: #eee;
}

.video-timeline2 {
  padding: 0.5rem 0;
}
.video-timeline2 > li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 0.5rem;
}
.video-timeline2 > li::before {
  content: '';
  border-left: 2px solid #eee;
  height: calc(100% + 5px);
  left: 0;
  top: 0;
  position: absolute;
}
.video-timeline2 > li::after {
  content: '├──';
  left: -3px;
  top: 0;
  position: absolute;
  color: #eee;
}
.video-timeline2 > li:last-child::before {
  content: '';
  border-left: 1px solid #fff;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}
.video-timeline2 > li:last-child::after {
  content: '└──';
  left: -3px;
  top: 0;
  position: absolute;
  color: #eee;
}

.ML__keyboard {
  z-index: 2000 !important;
}

.modal-message .close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 100;
}

.message-body p {
  margin-bottom: 5px !important;
  /* display: inline !important; */
}
.message-body p:empty {
  display: none !important;
}

.message-body p > br {
  display: none !important;
}
.message-body img {
  max-width: 100%;
}
