.quillWrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 0px) !important;
}

.resizable-component .quillWrapper {
  height: calc(100% - 27px) !important;
}

.quill-container {
  height: 100%;
}

.ql-container.ql-snow {
  border: 0 !important;
  height: calc(100% - 45px);
  background: #eeeeee;
}

.quillWrapper .ql-editor.ql-editor {
  min-height: 100%;
  font-size: 16px;
  height: 100%;
  line-height: 1.42;
  width: 100%;
  padding: 25px 30px 10px;
  tab-size: 4;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  background: #ffffff;
  position: absolute;
  top: 0px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.quillWrapper .ql-editor.ql-editor[contenteditable="false"] {
  display: none;
}

.quillWrapper .ql-editor.ql-editor::selection {
  background: #1890ff;
  color: #fff;
}

.ql-snow .ql-stroke.ql-thin,
.ql-snow .ql-thin {
  stroke-width: 1px !important;
}

.quillWrapper .ql-snow.ql-toolbar {
  padding-top: 8px;
  padding-bottom: 4px;
  /* background-color:rgba(0,0,0,.03); */
}

.quillWrapper .ql-snow.ql-toolbar .ql-formats {
  margin-bottom: 10px;
}

.ql-snow .ql-toolbar button svg,
.quillWrapper .ql-snow.ql-toolbar button svg {
  width: 22px;
  height: 22px;
}

.quillWrapper .ql-editor ul[data-checked='false']>li::before,
.quillWrapper .ql-editor ul[data-checked='true']>li::before {
  font-size: 1.35em;
  vertical-align: baseline;
  bottom: -0.065em;
  font-weight: 900;
  color: #222;
}

.quillWrapper .ql-snow .ql-stroke {
  stroke: rgba(63, 63, 63, 0.95);
  stroke-linecap: square;
  stroke-linejoin: initial;
  stroke-width: 1.7px;
}

.quillWrapper .ql-picker-label {
  font-size: 15px;
}

.quillWrapper .ql-snow .ql-active .ql-stroke {
  stroke-width: 2.25px;
}

.quillWrapper .ql-toolbar.ql-snow .ql-formats {
  vertical-align: top;
}

.ql-picker:not(.ql-background) {
  position: relative;
  top: 2px;
}

.ql-picker.ql-color-picker svg {
  width: 22px !important;
  height: 22px !important;
}

.quillWrapper .imageResizeActive img {
  display: block;
  cursor: pointer;
}

.quillWrapper .imageResizeActive~div svg {
  cursor: pointer;
}

.ql-toolbar.ql-snow {
  border: 0 !important;
  border-bottom: 1px solid #ccc !important;
  padding: 5px 0 !important;
}

/* temp biji */
.resizable-component.resizable-component {
  height: 100%;
}

.resizable-component .note-drag-bar {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 27px;
  background: #dddde7;
  /* border-radius: 4px 4px 0 0; */
  text-align: center;
  /* cursor: move; */
  box-sizing: border-box;
  font-size: 12px;
  color: #909098;
  padding: 0 8px;
}

.resizable-component .status-bar .text .text-msg {
  margin-right: 5px;
}

.resizable-component .close-btn {
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.resizable-component .quillWrapper .ql-snow.ql-toolbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.resizable-component .custom-btns {
  display: inline-block;
  flex-grow: 1;
  position: relative;
  min-height: 24px;
  margin-bottom: 5px;
}

.ql-bar,
.ql-bar-btn {
  position: relative;
  display: block;
}

.resizable-component .custom-btns .ql-capture-btn {
  width: 22px;
  height: 22px;
  /* display: inline-block; */
  text-align: center;
  line-height: 22px;
  background: #f79e00;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 2px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.resizable-component .custom-btns .ql-tag-btn {
  width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 22px;
  background: #2392e5;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 2px;
  text-decoration: none;
}

.resizable-component .custom-btns .ql-save-btn {
  position: absolute;
  right: 10px;
  width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 22px;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 2px;
  text-decoration: none;
}

.resizable-component .custom-btns .pz-icon {
  font-size: 20px;
}

.ql-image-preview {
  box-sizing: content-box;
  max-width: 100%;
  margin: 10px 0;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 2px;
  display: inline-flex;
  box-shadow: 0 2px 8px 0 hsla(0, 0%, 79.2%, 0.5);
}

.ql-image-preview .img-preview {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 2px;
  border: 5px solid #fff;
  cursor: default;
  opacity: 1;
  max-width: 100%;
}

.ql-image-preview .loading-img {
  cursor: progress;
  opacity: .5;
}

.ql-image-preview.uploaded .loading-img {
  cursor: default;
  opacity: 1;
}

.ql-tag-blot {
  display: inline-block;
  margin: 10px 0;
}

.ql-tag-blot .time-tag-item {
  transition: 0.2s;
  background: #e6f4ff;
  border-radius: 12px;
  height: 22px;
  line-height: 19px;
  display: inline-block;
  padding: 0 12px;
  font-size: 12px;
  color: #2392e5;
  border: 1px solid #e6f4ff;
  cursor: pointer;
  font-weight: 700;
  display: inline-flex;
}

.ql-tag-blot .time-tag-item:hover {
  border: 1px solid #2392e5;
}

.ql-tag-blot .time-tag-item .pz-icon {
  font-size: 18px;
}

.ql-tag-blot .time-tag-item__text {
  margin-top: 1px;
  font-weight: 700;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.ql-tag-blot .time-tag-item__text:before {
  content: '';
  height: 6px;
  width: 1px;
  display: inline-block;
  background: #2392e5;
  margin-right: 5px;
}

.ql-bar-btn:hover:before,
.ql-bar:hover:before {
  content: '';
  position: absolute;
  top: 30px;
  left: 15px;
  transform: translateX(-50%);
  border-color: transparent transparent #5d5d5d;
  border-style: solid;
  border-width: 0 6px 6px;
  z-index: 99;
}

.ql-bar-btn:hover:after,
.ql-bar:hover:after {
  flex-wrap: nowrap;
  display: flex;
  position: absolute;
  z-index: 200;
  top: 35px;
  left: -7px;
  word-break: keep-all;
  background: #5d5d5d;
  border-radius: 4px;
  padding: 5px 12px;
  line-height: 16px;
  font-size: 12px;
  color: hsla(0, 0%, 100%, 0.85);
  content: attr(labelTooltip);
  min-width: 22px;
}

.ql-bar-btn.ql-expanded:hover:before,
.ql-bar.ql-expanded:hover:before,
.ql-bar-btn.ql-expanded:hover:after,
.ql-bar.ql-expanded:hover:after {
  display: none;
}

.custom-btns .ql-bar-btn:hover:after,
.custom-btns .ql-bar:hover:after {
  left: -22px;
}

.ql-formats.ql-formats.ql-formats.ql-formats {
  margin-right: 0px;
}

.ql-size.ql-size.ql-size.ql-size {
  width: 50px;
  font-size: 16px;
  line-height: 25px;
}

.ql-size .ql-picker-item[data-value='12px'] {
  font-size: 12px;
}

.ql-size .ql-picker-item[data-value='14px'] {
  font-size: 14px;
}

.ql-size .ql-picker-item[data-value='16px'] {
  font-size: 16px;
}

.ql-size .ql-picker-item[data-value='18px'] {
  font-size: 18px;
}

.ql-size .ql-picker-item[data-value='20px'] {
  font-size: 20px;
}

.ql-size .ql-picker-item[data-value='22px'] {
  font-size: 22px;
}

.ql-size .ql-picker-item[data-value='24px'] {
  font-size: 24px;
}

.mathlive-blot {
  display: inline-block;
  max-width: 96%;
}

.qa-question .mathlive-blot {
  display: inline-block;
  max-width: 96%;
  overflow-x: auto;
  overflow-y: hidden;
}

.quillWrapper .ql-editor .mathlive-blot {
  display: inline-block;
  display: inline-flex;
  align-items: center;
  transition: outline-color 200ms ease;
  border: 1px dotted #000;
  /* outline: 3px solid rgb(222, 222, 222); */
  outline: 3px solid rgb(0, 0, 0, 0);
  font-weight: 300;
  line-height: 20px;
  font-family: Arial, Helvetica, sans-serif;
  white-space: normal;
}

.quillWrapper .ql-editor .mathlive-blot:hover {
  outline: 3px solid rgb(255, 200, 61);
}

.quillWrapper .ql-editor .mathlive-blot:focus,
.quillWrapper .ql-editor .mathlive-blot:focus-within {
  outline: 3px solid rgb(31, 137, 229);
}

.mathlive-blot .mathlive-markup {
  display: inline-block;
}

/* .quillWrapper .ql-editor .mathlive-blot .mathlive-markup {
  display: none;
} */

.quillWrapper .ql-editor .mathlive-blot>span {
  display: inline-block;
  max-width: 100%;
}

math-field {
  display: flex;
  align-items: center;
  display: block;
  /* font-size: 24px; */
  font-size: 16px;
  padding: 5px 10px;
  max-width: 100%;
  overflow: auto;
}

.ML__keyboard.ML__keyboard.ML__keyboard {
  z-index: 1100;
}