@import url('../../fonts/font-awesome/font-awesome.css');
@import url('../../fonts/iconfont/iconfont.css');

.pz-icon {
  font-size: 24px;

  font-weight: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: top;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.btn > .pz-icon {
  /* font-size:inherit !important; */
  vertical-align: text-bottom;
  /* margin-top: -.175rem; */
}
[class*='pz-icon']._xxs {
  font-size: 12px;
}

[class*='pz-icon']._xs {
  font-size: 14px;
}

[class*='pz-icon']._sm {
  font-size: 18px;
}

[class*='pz-icon']._lg {
  font-size: 36px;
}

[class*='pz-icon']._2x {
  font-size: 48px;
}

[class*='pz-icon']._3x {
  font-size: 64px;
}

.pz-rotate-90 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=1)';
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.pz-rotate-180 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.pz-rotate-270 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)';
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.pz-flip-horizontal {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)';
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.pz-flip-vertical {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .pz-rotate-90,
:root .pz-rotate-180,
:root .pz-rotate-270,
:root .pz-flip-horizontal,
:root .pz-flip-vertical {
  filter: none;
}
