/* Class-v2.css */
@import url('./common/bootstrap-4.4.1.css');

/* -- Class -- */
@import url('./class-v2/page-qa.css');
@import url('./class-v2/img-spirits.css');

/* -- Plugins -- */
@import url('./common/vue2-editor.css');
@import url('./common/animate-v4.css');
@import url('./common/aos.css');
@import url('./common/colors.css');
@import url('./common/pz_iconfont.css');
@import url('./common/sweetalert.css');
@import url('./common/utilities.css');

/* -- www-- */
@import url('./www-v2/page-style.css');

/* .web-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding-top: 66px;
} */

body {
  /* background-color: #f3f4f8;
  background-color: #edeff0; */
  position: relative;
  padding-top: 150px;
}

@media (min-width: 922px) {
  body {
    padding-top: 72px;
  }
}

/* v1 */
/* @import url('./common/icons.css'); */

#ZHINI-BTN-CONTAINER {
  bottom: 75px !important;
  /* transform: translateY(-50%) !important; */
}

header .dropup:hover > .dropdown-menu,
header .dropdown:hover > .dropdown-menu,
footer .dropup:hover > .dropdown-menu,
footer .dropdown:hover > .dropdown-menu {
  display: block;
}

header .dropup > .dropdown-toggle:active,
header .dropdown > .dropdown-toggle:active,
footer .dropup > .dropdown-toggle:active,
footer .dropdown > .dropdown-toggle:active {
  pointer-events: none;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* @media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
} */
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

.container-xxl {
  max-width: 1320px;
}
.container-xl {
  max-width: 1140px;
}
.container-lg {
  max-width: 960px;
}
.container-md {
  max-width: 720px;
}
.container-sm {
  max-width: 540px;
}

.border.border-dark,
.border-bottom.border-dark,
.border-top.border-dark,
.border-left.border-dark,
.border-right.border-dark {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.bg-image-center {
  background-size: cover;
  background-position: center;
}

.bg-image-fixes {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.img-fluid * {
  max-width: 100%;
}

@media (max-width: 991.98px) {
  .msg-pagination .pagination .page-item {
    display: none;
    font-size: 0.85rem;
  }

  .msg-pagination .pagination .page-item._previous,
  .msg-pagination .pagination .page-item._next,
  .msg-pagination .pagination .page-item._first,
  .msg-pagination .pagination .page-item._end,
  .msg-pagination .pagination .page-item.active {
    display: block !important;
  }
}
.articles-tabs {
  padding: 0 !important;
}
.articles-tabs .nav-link {
  color: #adb5bd !important;
  font-size: 1rem !important;
  padding: 0.75rem 0 !important;
  /* padding-right: 0 !important; */
}
.articles-tabs .nav-link.active {
  color: #009688 !important;
  border-bottom: 2px solid #009688;
}

.articles-tabs .btn {
  border-radius: 5rem !important;
  border-color: #adb5bd !important;
  color: #454545 !important;
}
.articles-tabs .btn:hover,
.articles-tabs .btn.active {
  border-color: #17b3a3 !important;
  color: #fff !important;
}
