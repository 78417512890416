/* Core.css */

html,
body {
  height: 100%;
}

.f-6 {
  font-size: 6px !important;
}

.f-8 {
  font-size: 8px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-32 {
  font-size: 32px !important;
}
.f-40 {
  font-size: 40px !important;
}

@media (max-width: 575.98px) {
  .f-xs-12 {
    font-size: 12px !important;
  }

  .f-xs-14 {
    font-size: 14px !important;
  }

  .f-xs-16 {
    font-size: 16px !important;
  }

  .f-xs-18 {
    font-size: 18px !important;
  }

  .f-xs-20 {
    font-size: 20px !important;
  }

  .f-xs-24 {
    font-size: 24px !important;
  }

  .f-xs-32 {
    font-size: 32px !important;
  }
  .f-xs-40 {
    font-size: 40px !important;
  }
}

@media (min-width: 576px) {
  .f-sm-12 {
    font-size: 12px !important;
  }
  .f-sm-14 {
    font-size: 14px !important;
  }
  .f-sm-16 {
    font-size: 16px !important;
  }
  .f-sm-18 {
    font-size: 18px !important;
  }
  .f-sm-20 {
    font-size: 20px !important;
  }
  .f-sm-24 {
    font-size: 24px !important;
  }
  .f-sm-32 {
    font-size: 32px !important;
  }
  .f-sm-40 {
    font-size: 40px !important;
  }
}

@media (min-width: 768px) {
  .f-md-12 {
    font-size: 12px !important;
  }
  .f-md-14 {
    font-size: 14px !important;
  }
  .f-md-16 {
    font-size: 16px !important;
  }
  .f-md-18 {
    font-size: 18px !important;
  }
  .f-md-20 {
    font-size: 20px !important;
  }
  .f-md-24 {
    font-size: 24px !important;
  }
  .f-md-32 {
    font-size: 32px !important;
  }
  .f-md-40 {
    font-size: 40px !important;
  }
}

@media (min-width: 992px) {
  .f-lg-12 {
    font-size: 12px !important;
  }
  .f-lg-14 {
    font-size: 14px !important;
  }
  .f-lg-16 {
    font-size: 16px !important;
  }
  .f-lg-18 {
    font-size: 18px !important;
  }
  .f-lg-20 {
    font-size: 20px !important;
  }
  .f-lg-24 {
    font-size: 24px !important;
  }
  .f-lg-32 {
    font-size: 32px !important;
  }
  .f-lg-40 {
    font-size: 40px !important;
  }
}

@media (min-width: 1200px) {
  .f-xl-12 {
    font-size: 12px !important;
  }
  .f-xl-14 {
    font-size: 14px !important;
  }
  .f-xl-16 {
    font-size: 16px !important;
  }
  .f-xl-18 {
    font-size: 18px !important;
  }
  .f-xl-20 {
    font-size: 20px !important;
  }
  .f-xl-24 {
    font-size: 24px !important;
  }
  .f-xl-32 {
    font-size: 32px !important;
  }
  .f-xl-40 {
    font-size: 40px !important;
  }
}

@media (min-width: 1600px) {
  .f-xxl-12 {
    font-size: 12px !important;
  }
  .f-xxl-14 {
    font-size: 14px !important;
  }
  .f-xxl-16 {
    font-size: 16px !important;
  }
  .f-xxl-18 {
    font-size: 18px !important;
  }
  .f-xxl-20 {
    font-size: 20px !important;
  }
  .f-xxl-24 {
    font-size: 24px !important;
  }
  .f-xxl-32 {
    font-size: 32px !important;
  }
  .f-xxl-40 {
    font-size: 40px !important;
  }
}

/* 列表两列（甩龙顺序）—— 主要应用在 course page  */

@media (min-width: 1600px) {
  .list-columns-2 {
    column-count: 2;
    -moz-column-count: 2;
    /* Firefox */
    -webkit-column-count: 2;
    /* Safari and Chrome */
    column-gap: 1.25rem;
    /* orphans: 1; */
    widows: 1;
    display: block;
    -webkit-column-break-inside: avoid;
  }
}

/* 段落显示最多行数 —— 主要应用在 note page  */
.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-5 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-1 {
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
}

.btn.disabled,
.btn:disabled {
  /* opacity: 0.35; */
  /* background-color: rgba(0, 0, 0, 0.1); */
  /* border-color: rgba(0, 0, 0, 0); */
  /* pointer-events: none !important; */
  /* color: rgba(0, 0, 0, 0.4); */
  cursor: not-allowed !important;
}

[class*='btn-outline'].disabled,
[class*='btn-outline']:disabled {
  /* opacity: 0.35;
  background-color: transparent !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: rgba(0, 0, 0, 0.2) !important; */
  /* pointer-events: none !important; */
  cursor: not-allowed !important;
}

.btn-link.disabled,
.btn-link:disabled {
  /* background-color: transparent !important;
  color: rgba(0, 0, 0, 0.25) !important; */
  cursor: not-allowed !important;
}

.btn-link:disabled,
.btn-link.disabled {
  pointer-events: auto !important;
}

/* 灰显效果  */

.filter-gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  opacity: 0.3;
}
a.disabled {
  /* pointer-events: none !important; */
  filter: alpha(opacity=50); /*IE滤镜，透明度50%*/
  -moz-opacity: 0.5; /*Firefox私有，透明度50%*/
  opacity: 0.5; /*其他，透明度50%*/
  cursor: not-allowed !important;
}
a.disabled:after {
  /* pointer-events: none !important; */
  cursor: not-allowed !important;
}

/* 图片（img）以背景形式显示  */

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

/* 控制展开图标切换 1:关，2:开 */

.collapse-icon ._i-1,
.collapse-icon.collapsed ._i-2 {
  display: none;
}

.collapse-icon ._i-2,
.collapse-icon.collapsed ._i-1 {
  display: inline-block;
}

.border-dark-1 {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.border-dark-2 {
  border-color: rgba(0, 0, 0, 0.25) !important;
}
.border-dark-3 {
  border-color: rgba(0, 0, 0, 0.5) !important;
}

.accordion-separate>.card {
  margin-bottom: 1rem;
}

.accordion-separate>.card:not(:last-of-type) {
  border-bottom: 1px solid #e9ecef;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.accordion-separate>.card:not(:first-of-type) {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.accordion .accordion-title {
  position: relative;
  color: #37474f;
  padding-left: 0;
  padding-right: 0;
}

.accordion .accordion-title[aria-expanded=true]{
  color: #17B3A3;
  font-weight: bold;
}



.accordion .accordion-title:before,
.accordion .accordion-title:after {
  font-family: "pz-icon" !important;
  font-size: 24px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0px;
  right: 0px;
  transition: all .3s linear 0s;
}

.accordion .accordion-title::before {
  content: "\e61e";
}

.accordion .accordion-title:after {
  content: "\e626";
}

.accordion .accordion-title[aria-expanded=false]::before {
  opacity: .4;
}

.accordion .accordion-title[aria-expanded=false]:after {
  opacity: 0;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion .accordion-title[aria-expanded=true]:before {
  opacity: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion .accordion-title[aria-expanded=true]:after {
  opacity: 1;
}