@font-face {
  font-family: "pz-icon"; /* Project id 2332257 */
  src: url('iconfont.eot?t=1697206293033'); /* IE9 */
  src: url('iconfont.eot?t=1697206293033#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('iconfont.woff2?t=1697206293033') format('woff2'),
       url('iconfont.woff?t=1697206293033') format('woff'),
       url('iconfont.ttf?t=1697206293033') format('truetype'),
       url('iconfont.svg?t=1697206293033#pz-icon') format('svg');
}

.pz-icon {
  font-family: "pz-icon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-verified_user:before {
  content: "\e675";
}

.icon-alumni:before {
  content: "\e674";
}

.icon-vip-1v1:before {
  content: "\e673";
}

.icon-help:before {
  content: "\e672";
}

.icon-chat:before {
  content: "\e670";
}

.icon-discussion:before {
  content: "\e671";
}

.icon-save:before {
  content: "\e66f";
}

.icon-note-video:before {
  content: "\e66d";
}

.icon-note-exercises:before {
  content: "\e66e";
}

.icon-ranking-5:before {
  content: "\e64e";
}

.icon-ranking-1:before {
  content: "\e66c";
}

.icon-file-excel:before {
  content: "\e66b";
}

.icon-add:before {
  content: "\e601";
}

.icon-app-download:before {
  content: "\e602";
}

.icon-bookmarks:before {
  content: "\e603";
}

.icon-check-box-blank:before {
  content: "\e604";
}

.icon-call:before {
  content: "\e605";
}

.icon-bookmark:before {
  content: "\e606";
}

.icon-camera:before {
  content: "\e607";
}

.icon-cancel:before {
  content: "\e608";
}

.icon-check-box:before {
  content: "\e609";
}

.icon-close:before {
  content: "\e60a";
}

.icon-check:before {
  content: "\e60b";
}

.icon-compare-arrows:before {
  content: "\e60c";
}

.icon-activity:before {
  content: "\e60d";
}

.icon-chevron-right:before {
  content: "\e60e";
}

.icon-clock:before {
  content: "\e60f";
}

.icon-chevron-left:before {
  content: "\e610";
}

.icon-alarm:before {
  content: "\e611";
}

.icon-contact-phone:before {
  content: "\e612";
}

.icon-contact-mail-bold:before {
  content: "\e613";
}

.icon-email-outline:before {
  content: "\e614";
}

.icon-description:before {
  content: "\e615";
}

.icon-contact-phone-bold:before {
  content: "\e616";
}

.icon-create:before {
  content: "\e617";
}

.icon-coupon:before {
  content: "\e618";
}

.icon-comment:before {
  content: "\e619";
}

.icon-check-circle:before {
  content: "\e61a";
}

.icon-error:before {
  content: "\e61b";
}

.icon-check-circle-outline:before {
  content: "\e61c";
}

.icon-enter:before {
  content: "\e61d";
}

.icon-expand-more:before {
  content: "\e61e";
}

.icon-exam-list:before {
  content: "\e61f";
}

.icon-error-outline:before {
  content: "\e620";
}

.icon-email:before {
  content: "\e621";
}

.icon-filter:before {
  content: "\e622";
}

.icon-font-size:before {
  content: "\e623";
}

.icon-flash-card:before {
  content: "\e624";
}

.icon-first-page:before {
  content: "\e625";
}

.icon-expand-less:before {
  content: "\e626";
}

.icon-dot:before {
  content: "\e627";
}

.icon-file-zip:before {
  content: "\e628";
}

.icon-info:before {
  content: "\e629";
}

.icon-insert-chart:before {
  content: "\e62a";
}

.icon-fullscreen-exit:before {
  content: "\e62b";
}

.icon-loop:before {
  content: "\e62c";
}

.icon-fullscreen:before {
  content: "\e62d";
}

.icon-home:before {
  content: "\e62e";
}

.icon-location:before {
  content: "\e62f";
}

.icon-looks-5:before {
  content: "\e630";
}

.icon-help-outline:before {
  content: "\e631";
}

.icon-issue:before {
  content: "\e632";
}

.icon-last-page:before {
  content: "\e633";
}

.icon-modal-setting:before {
  content: "\e634";
}

.icon-modal-layout-1:before {
  content: "\e635";
}

.icon-notifications:before {
  content: "\e636";
}

.icon-looks-4:before {
  content: "\e637";
}

.icon-looks-1:before {
  content: "\e638";
}

.icon-modal-layout-3:before {
  content: "\e639";
}

.icon-modal-layout-2:before {
  content: "\e63a";
}

.icon-history:before {
  content: "\e63b";
}

.icon-logout:before {
  content: "\e63c";
}

.icon-message-none:before {
  content: "\e63d";
}

.icon-gift:before {
  content: "\e63e";
}

.icon-looks-2:before {
  content: "\e63f";
}

.icon-notifications-none:before {
  content: "\e640";
}

.icon-note-download:before {
  content: "\e641";
}

.icon-more-vert:before {
  content: "\e642";
}

.icon-play-circle:before {
  content: "\e643";
}

.icon-question-answer:before {
  content: "\e644";
}

.icon-ranking-2:before {
  content: "\e645";
}

.icon-note:before {
  content: "\e646";
}

.icon-note-list:before {
  content: "\e647";
}

.icon-search:before {
  content: "\e648";
}

.icon-open-in-new:before {
  content: "\e649";
}

.icon-playlist-check:before {
  content: "\e64a";
}

.icon-shuffle:before {
  content: "\e64b";
}

.icon-public-web:before {
  content: "\e64c";
}

.icon-modal-layout-4:before {
  content: "\e64d";
}

.icon-person-info:before {
  content: "\e64f";
}

.icon-ranking-4:before {
  content: "\e650";
}

.icon-star:before {
  content: "\e651";
}

.icon-referral:before {
  content: "\e652";
}

.icon-menu:before {
  content: "\e653";
}

.icon-ranking-3:before {
  content: "\e654";
}

.icon-person:before {
  content: "\e655";
}

.icon-star-half:before {
  content: "\e656";
}

.icon-message:before {
  content: "\e657";
}

.icon-phone-msg:before {
  content: "\e658";
}

.icon-trash:before {
  content: "\e659";
}

.icon-remove:before {
  content: "\e65a";
}

.icon-warning:before {
  content: "\e65b";
}

.icon-study:before {
  content: "\e65c";
}

.icon-thumb-up:before {
  content: "\e65d";
}

.icon-star-border:before {
  content: "\e65e";
}

.icon-service:before {
  content: "\e65f";
}

.icon-sheet:before {
  content: "\e660";
}

.icon-settings:before {
  content: "\e661";
}

.icon-weixin:before {
  content: "\e662";
}

.icon-trophy:before {
  content: "\e663";
}

.icon-user-identity:before {
  content: "\e664";
}

.icon-user-group:before {
  content: "\e665";
}

.icon-file-pdf:before {
  content: "\e666";
}

.icon-today:before {
  content: "\e667";
}

.icon-weixin-circle:before {
  content: "\e668";
}

.icon-thumb-down:before {
  content: "\e669";
}

.icon-looks-3:before {
  content: "\e66a";
}

