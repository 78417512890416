/* =============
    问答
================ */
@media (max-width: 575.98px) {
  #qaFilter {
    display: block !important;
  }
}

/*
 * QA Layout
 */
.qaSidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  height: calc(100% - 64px);
  /* box-shadow: inset 1px 0 0 rgba(0, 0, 0, .1); */
}

.qa-list .qa-votes-group .cp {
  width: 60px;
  height: 48px;
  padding-top: 3px;
  text-align: center;
  display: inline-block;
  font-size: 1.2em;
  color: #555;
}

.qa-list .qa-votes-group .cp small {
  font-size: 65%;
  display: block;
  color: #999;
}

.qa-list .qa-votes-group .cp.answered i {
  display: none;
}

.qa-list .qa-votes-group .cp.answered-accepted {
  color: #009688;
}

.qa-list .qa-votes-group .cp.answered-accepted i {
  display: inline-block;
}

.qa-list .qa-votes-group .cp.answered-accepted small {
  color: #009688;
}

/*  qa-option-list */

.qa-option-list .list-group-item:hover {
  cursor: pointer;
}

.qa-option-list .qa-option-level {
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.3);
  margin-right: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qa-option-list .qa-option-level._empty-border {
  border-color: #fff;
}

.qa-option-list .selected .qa-option-level {
  background-color: #17b3a4;
  color: #fff;
  border-color: #17b3a4;
}

.qa-option-list .incorrect .qa-option-level:before,
.qa-option-list .correct .qa-option-level:before {
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  left: -2px;
  top: -2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.qa-option-list .incorrect .qa-option-level:before {
  content: 'x';
  background-color: #dc3545;
}

.qa-option-list .correct .qa-option-level:before {
  content: '√';
  background-color: #28a745;
}
.qa-option-list.checkbox .qa-option-level,
.qa-option-list.checkbox .incorrect .qa-option-level:before,
.qa-option-list.checkbox .correct .qa-option-level:before {
  border-radius: 5px !important;
}

.qa-option-list .text-danger,
.qa-option-list .text-success {
  display: none;
}

.qa-option-list .incorrect .text-danger {
  display: inline-block;
}

.qa-option-list .correct .text-success {
  display: inline-block;
}

.qa-option-list .qa-option-text {
  display: flex;
}

/* .qa-option-list .qa-option-text>* {
    flex-grow: 1;
    text-align: center;
} */

.qa-option-list .qa-option-text p {
  margin-bottom: 0;
}

/* .qa-tab {
    background-color: #f9fafa;
    box-shadow: 0 1px 0 0 #e8ebed;
    display: flex;
    justify-content: flex-start;
    padding: 10px 10px;
    flex-direction: column;
} */

/* @media (min-width: 768px) {
    .qa-tab {
        flex-direction: row;
        align-items: center;
    }
} */
/* .qa-tab-list{
    display: none;
} */
/* .qa-tab-list .nav-link{

        color: #999;
}
.qa-tab-list .nav-link:hover,
.qa-tab-list .nav-link:focus {
    background-color: transparent;
    color: #333;
}

.qa-tab-list>li.active>.nav-link,
.qa-tab-list>li.active>.nav-link:hover,
.qa-tab-list>li.active>.nav-linka:focus {
    color: #009688;
    background-color: transparent;
    border-bottom: 2px solid #009688;
} */

.input-search-dark {
  position: relative;
}
.input-search-dark .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.input-search-btn {
  position: absolute;
  top: 0px;
  right: 0;
  height: 100%;
  padding: 0 10px;
  background: 0 0;
  border: none;
  border-radius: 0 200px 200px 0;
  font-size: 10px !important;
}

/* .m-qa-tab-list .btn{
    margin-top:10px;
    text-align: left;
}
.m-qa-tab-list  .dropdown-toggle::after{
    float: right;
    margin-top: 6px;
}
.m-qa-tab-list .dropdown-menu{
    right: 0;
}
.m-qa-tab-list .dropdown-menu >li >a{
    padding: 10px;
    border-bottom: solid 1px #eee;
}

.m-qa-tab-list .dropdown-menu >li >a .badge{
    float: right;
} */

/* @media (min-width: 768px) {
      .qa-tab-list{
          border:0;
          margin-bottom: 0;
          display: block;
      }
      .qa-tab-list li{
          width: auto;
          float: left;
      }
      .qa-tab-list li > a{
          padding-right: 10px;
          padding-left: 10px;
          font-size: 14px;
      }
      .qa-tab-list{
        display: none;
    }
  }
  @media (min-width: 992px) {
      .qa-tab-list li > a{
          padding-right: 15px;
          padding-left: 15px;
          font-size: 16px;
      }
      .m-qa-tab-list{
        display: none;
    }
    .qa-tab-list{
      display: block;
  }
  } */

/* .qa-tab-list .badge {
      position: absolute;
      top: -10px;
      margin-left: -5px;
  }
  .qa-tab-list .active > a > .badge {
      color: #fff;
      background-color: #f05050;
  }
  
  .qa-tab-list ._badge-dot{
    top: 0px;
  } */

/* .qa-tab .form-control{
    font-size: 1.25rem;
  } */

/* .qa-tab-search {
      display: flex;
      align-items: center;
      margin-right: 0;
  }
  
  .qa-tab-search .input-group {
      margin-bottom: 0;
      width: calc(100% - 44px);
      height: 34px;
  }
  
  .qa-tab-search .input-group .form-control {
      height: 34px;
  }
  .qa-tab-search .input-group .btn {
      position: absolute;
      right: 0px;
      top: 0px;
      height: 34px;
      color: #999;
  } */

/* @media(min-width: 768px) {
      .qa-tab-search{
          margin-right: auto;
      }
      .qa-tab-search .input-group {
          width: 180px;
      }
      .qa-tab-search .dropdown .btn-default {
          background-color: transparent;
          border: 0;
      }
  } */
/* @media(min-width: 992px) {
      .qa-tab-search .input-group {
          width: 250px;
      }
  }
  
  .qa-tab-search .dropdown .dropdown-menu {
      right: 0;
      left: auto;
  } */
/*
.qa-tab .dropdown-menu>.active>a,
.qa-tab .dropdown-menu>.active>a:hover,
.qa-tab .dropdown-menu>.active>a:focus {
    color: #fff;
    background-color: #009688;
    outline: 0;
} */

/* .img-msg .badge {
    position: absolute;
    top: 0px;
    right: 0px; 
}*/

/*
  .qa-breadcrumb {
      padding: 10px;
      border-bottom: solid 1px #eee;
      display: flex;
      align-items: center;
  }
  
  .qa-breadcrumb .dropdown {
      display: inline-block;
  } */

@media (min-width: 768px) {
  /*.qa-breadcrumb {
        padding: 10px;
    }*/
}

/*.qa-filter {
      background-color: rgba(0, 0, 0, .03);
      padding: 4px 8px;
      border-bottom: solid 1px rgba(0, 0, 0, .1);
  }*/

/* .media-list {
    min-height: 400px;
} */

/*
@media(max-width: 576px) {
    .media-list {
        padding-left: 10px;
        padding-right: 10px;
    }
    .media-body.p-x-20 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
} */

.media-list .media._unread * {
  /* background-color:rgba(214, 252, 255, 0.3); */
  font-weight: 700 !important;
}

.media-list .media ._badge-dot {
  left: -10px;
  top: 20px;
}

/* .media-list .media-body a:hover {
    text-decoration: underline;
    color: #212121; 
}*/

.qa-list *,
.qa-question *,
.qa-anwser * {
  word-break: break-all;
  /*支持IE，chrome，FF不支持*/
  word-wrap: break-word;
  white-space: break-sp;
  /*支持IE，chrome，FF*/
}

.qa-list pre,
.qa-list .pre,
.qa-question pre,
.qa-question .pre,
.qa-anwser pre,
.qa-anwser .pre {
  white-space: normal !important;
}

/* .qa-question-body .label,
.qa-list .label,
.qa-list .btn-default {
  border-radius: 15px;
    border: solid 3px #ddd;
    color: #999;
    margin-right: 10px;
    padding: 3px 10px;
    font-size: 10px; 
}*/

/*@media(max-width: 576px) {
    .qa-question-body .label,
    .qa-list .label {
         border: solid 2px #ddd;
        padding: 2px 5px; 
    }
}*/

/*--------------------*/

/* .qa-detail .card-header {
    background-color: #f9fafa;
    box-shadow: 0 1px 0 0 #e8ebed;
    padding: 15px 30px;
}

.qa-tag {
    margin-bottom: 10px !important;
}

.qa-tag li {
    border-radius: 15px;
    border: solid 3px #ddd;
    color: #999;
    margin-right: 10px;
    padding: 2px 10px;
    font-size: 14px;
    background-color: rgba(255, 255, 255, .5);
}
 */

/*.qa-tag li::after{
      content: '>';
      padding-left: 10px;
      opacity: .2;
  
  }
  .qa-tag li:last-child:after{
      content: '';
      padding-left: 10px;
  }*/

/* @media(min-width: 768px) {
      .qa-detail .card-block {
          padding-left: 50px;
          padding-right: 50px;
      }
  }
  
  @media(min-width: 950px) {
      .qa-detail .card-block {
          padding-right: 100px;
      }
  }
  
  @media(min-width: 1200px) {
      .qa-detail .card-block {
          padding-right: 250px;
      }
  } */

/* .qa-detail .media-left {
      width: 70px;
      display: inline-block;
      float: left;
      text-align: right;
      padding-right: 20px;
  } */

/* .qa-comments {
   margin-top: 20px; 
}*/

/* .qa-comments-add {
      margin-top: 30px;
  
      margin-bottom: 30px;
  } */

/* .qa-comments-input {
    padding: 5px;
} */

/*.qa-comments-add .input-group {
    width: 100%;
    margin-bottom: 0;
}*/

/* .qa-anwser-header {
      display: flex;
      align-items: center;
  }
  
  .qa-anwser-header .badge {
      font-weight: 400;
  } */

/* .qa-anwser .media {
   border-bottom: solid 1px rgba(0, 0, 0, .1);
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    overflow: visible;
    margin-top: -3px; 
}*/

/*.qa-anwser .media.isaccept{
      border-bottom: solid 3px #26A69A;
      border-top: solid 3px #26A69A;
      background-color: #FAFFFC;
  }*/

.qa-anwser .media .badge-default {
  position: absolute;
  bottom: -8px;
  left: 50%;
  font-weight: 300;
  background-color: #ccc;
  width: 100px;
  margin-left: -50px;
  z-index: 100;
}

.qa-anwser .qa-comments .media {
  border: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  /*margin-top: -40px;*/
}

.qa-anwser .qa-comments .media:first-childs {
  margin-top: -40px;
}

.btn-comment .badge {
  margin-top: -12px;
  margin-left: 3px;
  font-weight: 400;
}

/* .qa-teacher {

background-color:#0b83e6;

} */
.qa-teacher-state {
  color: #fff;
  padding: 2px 5px;
  font-size: 14px;
  position: absolute;
  right: 0.5rem;
  top: 0;
  /* background-color:#0b83e6; */
}
.qa-teacher .small:before,
.qa-teacher footer:before,
.qa-teacher small:before {
  content: '\2014 \00A0';
}

.qa-accept-state {
  color: #fff;
  padding: 2px 5px;
  font-size: 14px;
  position: absolute;
  right: 0.5rem;
  top: 0;
  background-color: #009688;
}

/*
  
  .qa-anwser-body {
      padding-bottom: 20px;
  }
  
  .qa-anwser-body .media {
      border-top: solid 1px rgba(0, 0, 0, .1);
      padding: 20px;
      margin: 0;
  }
  
  .qa-anwser-body .media:first-child {
      border-top: 0px;
  }
  
  
  
  .qa-anwser-list{
      margin-bottom: 40px;
      padding-top: 30px;
      border-top: solid 3px rgba(0, 0, 0, .1);
  }*/

/* .qa-edit-header {
      border-bottom: solid 3px rgba(0, 0, 0, .1);
      margin-bottom: 20px;
  } */

/* .qa-edit {
      padding: 20px;
  } */

/* .qa-votecell {
      padding-right: 20px;
      text-align: center;
  }
  
  .qa-votecell .btn {
      color: #666;
      margin-top: -10px;
  }
  
  .qa-votecell h4 {
      color: #666;
      margin-top: 5px;
  } */

.qa-post-text img {
  border: solid 1px rgba(0, 0, 0, 0.05);
  max-width: 100% !important;
}

/* .qa-related .card-title {
      border-bottom: solid 1px rgba(0, 0, 0, .1);
      padding: 20px;
      margin: 0;
  }
  
  .qa-related li {
      padding-left: 40px;
      margin-bottom: 10px;
      position: relative;
  }
  
  .qa-related li small {
      display: block;
      position: absolute;
      width: 30px;
      padding: 2px 0;
      text-align: center;
      left: 0;
      top: 2px;
      background-color: #eff0f1;
  }
  
  .qa-related a {
      word-wrap: break-word;
  } */

.qa-taglist {
  display: inline-block;
  padding: 2px 8px;
  border: solid 2px #eee;
  margin-right: 8px;
  margin-bottom: 10px;
  background-color: transparent;
  font-size: 12px;
}

.qa-taglist:hover,
.qa-taglist.active {
  border-color: #009688;
}

/* .no-question {
      text-align: center;
      padding-top: 100px;
      padding-left: 30px;
      padding-right: 30px;
  } */

/* .my-question {
    min-height: 300px;
}

.my-question .list-group-item {
    padding: 10px 100px 10px 80px;
    border-radius: 0;
    position: relative;
}

.my-question .list-group-item:first-child {
    border-top: 0;
}

.my-question .list-group-item .relativetime {
    position: absolute;
    right: 20px;
    top: 20px;
}

.my-question .list-group-item .votes {
    background-color: #eff0f1;
    width: 40px;
    padding: 2px;
    text-align: center;
    position: absolute;
    left: 20px;
    top: 20px;
}

.my-question .list-group-item .votes.answered-accepted {
    color: #fff;
    background-color: #009688;
} */

/* .search-input {
    background-color: #E4E9F0;
}

.search-list .list-group-item {
    border-radius: 0;
}

.search-list .list-group-item h4 {
    color: #006193;
} */

.float-ask-m {
  z-index: 1;
  bottom: 30px;
  position: fixed;
  width: 100%;
}

.float-ask-m a {
  border-radius: 80px;
  height: 64px;
  width: 64px;
  margin-right: 15px;
  float: right;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
}

@media (min-width: 768px) {
  .float-ask-m a {
    height: 72px;
    width: 72px;
    font-size: 20px;
  }
}

.qa-list-note li span:before {
  content: '、';
  margin-left: 8px;
}

.qa-list-note li span:first-child:before {
  content: '';
  margin-left: 0px;
}

/* .highlight{
      color: red;
  } */
