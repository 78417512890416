.red-100 {
  color: #ffeaea !important;
}
.red-200 {
  color: #fad3d3 !important;
}
.red-300 {
  color: #fab4b4 !important;
}
.red-400 {
  color: #fa9898 !important;
}
.red-500 {
  color: #fa7a7a !important;
}
.red-600 {
  color: #f96868 !important;
}
.red-700 {
  color: #e9595b !important;
}
.red-800 {
  color: #d6494b !important;
}
.pink-100 {
  color: #fce4ec !important;
}
.pink-200 {
  color: #ffccde !important;
}
.pink-300 {
  color: #fba9c6 !important;
}
.pink-400 {
  color: #fb8db4 !important;
}
.pink-500 {
  color: #f978a6 !important;
}
.pink-600 {
  color: #f96197 !important;
}
.pink-700 {
  color: #f44c87 !important;
}
.pink-800 {
  color: #e53b75 !important;
}
.purple-100 {
  color: #f6f2ff !important;
}
.purple-200 {
  color: #e3dbf4 !important;
}
.purple-300 {
  color: #d2c5ec !important;
}
.purple-400 {
  color: #bba7e4 !important;
}
.purple-500 {
  color: #a58add !important;
}
.purple-600 {
  color: #926dde !important;
}
.purple-700 {
  color: #7c51d1 !important;
}
.purple-800 {
  color: #6d45bc !important;
}
.indigo-100 {
  color: #edeff9 !important;
}
.indigo-200 {
  color: #dadef5 !important;
}
.indigo-300 {
  color: #bcc5f4 !important;
}
.indigo-400 {
  color: #9daaf3 !important;
}
.indigo-500 {
  color: #8897ec !important;
}
.indigo-600 {
  color: #677ae4 !important;
}
.indigo-700 {
  color: #5166d6 !important;
}
.indigo-800 {
  color: #465bd4 !important;
}
.blue-100 {
  color: #e8f1f8 !important;
}
.blue-200 {
  color: #d5e4f1 !important;
}
.blue-300 {
  color: #bcd8f1 !important;
}
.blue-400 {
  color: #a2caee !important;
}
.blue-500 {
  color: #89bceb !important;
}
.blue-600 {
  color: #62a8ea !important;
}
.blue-700 {
  color: #4e97d9 !important;
}
.blue-800 {
  color: #3583ca !important;
}
.cyan-100 {
  color: #ecf9fa !important;
}
.cyan-200 {
  color: #d3eff2 !important;
}
.cyan-300 {
  color: #baeaef !important;
}
.cyan-400 {
  color: #9ae1e9 !important;
}
.cyan-500 {
  color: #77d6e1 !important;
}
.cyan-600 {
  color: #57c7d4 !important;
}
.cyan-700 {
  color: #47b8c6 !important;
}
.cyan-800 {
  color: #37a9b7 !important;
}
.teal-100 {
  color: #ecfdfc !important;
}
.teal-200 {
  color: #cdf4f1 !important;
}
.teal-300 {
  color: #99e1da !important;
}
.teal-400 {
  color: #79d1c9 !important;
}
.teal-500 {
  color: #56bfb5 !important;
}
.teal-600 {
  color: #3aa99e !important;
}
.teal-700 {
  color: #269b8f !important;
}
.teal-800 {
  color: #178d81 !important;
}
.green-100 {
  color: #e7faf2 !important;
}
.green-200 {
  color: #bfedd8 !important;
}
.green-300 {
  color: #9fe5c5 !important;
}
.green-400 {
  color: #7dd3ae !important;
}
.green-500 {
  color: #5cd29d !important;
}
.green-600 {
  color: #46be8a !important;
}
.green-700 {
  color: #36ab7a !important;
}
.green-800 {
  color: #279566 !important;
}
.light-green-100 {
  color: #f1f7ea !important;
}
.light-green-200 {
  color: #e0ecd1 !important;
}
.light-green-300 {
  color: #cadfb1 !important;
}
.light-green-400 {
  color: #bad896 !important;
}
.light-green-500 {
  color: #acd57c !important;
}
.light-green-600 {
  color: #9ece67 !important;
}
.light-green-700 {
  color: #83b944 !important;
}
.light-green-800 {
  color: #70a532 !important;
}
.yellow-100 {
  color: #fffae7 !important;
}
.yellow-200 {
  color: #f9eec1 !important;
}
.yellow-300 {
  color: #f6e7a9 !important;
}
.yellow-400 {
  color: #f8e59b !important;
}
.yellow-500 {
  color: #f7e083 !important;
}
.yellow-600 {
  color: #f7da64 !important;
}
.yellow-700 {
  color: #f9cd48 !important;
}
.yellow-800 {
  color: #fbc02d !important;
}
.orange-100 {
  color: #fff3e6 !important;
}
.orange-200 {
  color: #ffddb9 !important;
}
.orange-300 {
  color: #fbce9d !important;
}
.orange-400 {
  color: #f6be80 !important;
}
.orange-500 {
  color: #f4b066 !important;
}
.orange-600 {
  color: #f2a654 !important;
}
.orange-700 {
  color: #ec9940 !important;
}
.orange-800 {
  color: #e98f2e !important;
}
.brown-100 {
  color: #fae6df !important;
}
.brown-200 {
  color: #e2bdaf !important;
}
.brown-300 {
  color: #d3aa9c !important;
}
.brown-400 {
  color: #b98e7e !important;
}
.brown-500 {
  color: #a17768 !important;
}
.brown-600 {
  color: #8d6658 !important;
}
.brown-700 {
  color: #7d5b4f !important;
}
.brown-800 {
  color: #715146 !important;
}
.grey-100 {
  color: #fafafa !important;
}
.grey-200 {
  color: #eee !important;
}
.grey-300 {
  color: #e0e0e0 !important;
}
.grey-400 {
  color: #bdbdbd !important;
}
.grey-500 {
  color: #9e9e9e !important;
}
.grey-600 {
  color: #757575 !important;
}
.grey-700 {
  color: #616161 !important;
}
.grey-800 {
  color: #424242 !important;
}
.blue-grey-100 {
  color: #f3f7f9 !important;
}
.blue-grey-200 {
  color: #e4eaec !important;
}
.blue-grey-300 {
  color: #ccd5db !important;
}
.blue-grey-400 {
  color: #a3afb7 !important;
}
.blue-grey-500 {
  color: #76838f !important;
}
.blue-grey-600 {
  color: #526069 !important;
}
.blue-grey-700 {
  color: #37474f !important;
}
.blue-grey-800 {
  color: #263238 !important;
}

.bg-red-100 {
  background-color: #ffeaea !important;
}
.bg-red-200 {
  background-color: #fad3d3 !important;
}
.bg-red-300 {
  background-color: #fab4b4 !important;
}
.bg-red-400 {
  background-color: #fa9898 !important;
}
.bg-red-500 {
  background-color: #fa7a7a !important;
}
.bg-red-600 {
  background-color: #f96868 !important;
}
.bg-red-700 {
  background-color: #e9595b !important;
}
.bg-red-800 {
  background-color: #d6494b !important;
}
.bg-pink-100 {
  background-color: #fce4ec !important;
}
.bg-pink-200 {
  background-color: #ffccde !important;
}
.bg-pink-300 {
  background-color: #fba9c6 !important;
}
.bg-pink-400 {
  background-color: #fb8db4 !important;
}
.bg-pink-500 {
  background-color: #f978a6 !important;
}
.bg-pink-600 {
  background-color: #f96197 !important;
}
.bg-pink-700 {
  background-color: #f44c87 !important;
}
.bg-pink-800 {
  background-color: #e53b75 !important;
}
.bg-purple-100 {
  background-color: #f6f2ff !important;
}
.bg-purple-200 {
  background-color: #e3dbf4 !important;
}
.bg-purple-300 {
  background-color: #d2c5ec !important;
}
.bg-purple-400 {
  background-color: #bba7e4 !important;
}
.bg-purple-500 {
  background-color: #a58add !important;
}
.bg-purple-600 {
  background-color: #926dde !important;
}
.bg-purple-700 {
  background-color: #7c51d1 !important;
}
.bg-purple-800 {
  background-color: #6d45bc !important;
}
.bg-indigo-100 {
  background-color: #edeff9 !important;
}
.bg-indigo-200 {
  background-color: #dadef5 !important;
}
.bg-indigo-300 {
  background-color: #bcc5f4 !important;
}
.bg-indigo-400 {
  background-color: #9daaf3 !important;
}
.bg-indigo-500 {
  background-color: #8897ec !important;
}
.bg-indigo-600 {
  background-color: #677ae4 !important;
}
.bg-indigo-700 {
  background-color: #5166d6 !important;
}
.bg-indigo-800 {
  background-color: #465bd4 !important;
}
.bg-blue-100 {
  background-color: #e8f1f8 !important;
}
.bg-blue-200 {
  background-color: #d5e4f1 !important;
}
.bg-blue-300 {
  background-color: #bcd8f1 !important;
}
.bg-blue-400 {
  background-color: #a2caee !important;
}
.bg-blue-500 {
  background-color: #89bceb !important;
}
.bg-blue-600 {
  background-color: #62a8ea !important;
}
.bg-blue-700 {
  background-color: #4e97d9 !important;
}
.bg-blue-800 {
  background-color: #3583ca !important;
}
.bg-cyan-100 {
  background-color: #ecf9fa !important;
}
.bg-cyan-200 {
  background-color: #d3eff2 !important;
}
.bg-cyan-300 {
  background-color: #baeaef !important;
}
.bg-cyan-400 {
  background-color: #9ae1e9 !important;
}
.bg-cyan-500 {
  background-color: #77d6e1 !important;
}
.bg-cyan-600 {
  background-color: #57c7d4 !important;
}
.bg-cyan-700 {
  background-color: #47b8c6 !important;
}
.bg-cyan-800 {
  background-color: #37a9b7 !important;
}
.bg-teal-100 {
  background-color: #ecfdfc !important;
}
.bg-teal-200 {
  background-color: #cdf4f1 !important;
}
.bg-teal-300 {
  background-color: #99e1da !important;
}
.bg-teal-400 {
  background-color: #79d1c9 !important;
}
.bg-teal-500 {
  background-color: #56bfb5 !important;
}
.bg-teal-600 {
  background-color: #3aa99e !important;
}
.bg-teal-700 {
  background-color: #269b8f !important;
}
.bg-teal-800 {
  background-color: #178d81 !important;
}
.bg-green-100 {
  background-color: #e7faf2 !important;
}
.bg-green-200 {
  background-color: #bfedd8 !important;
}
.bg-green-300 {
  background-color: #9fe5c5 !important;
}
.bg-green-400 {
  background-color: #7dd3ae !important;
}
.bg-green-500 {
  background-color: #5cd29d !important;
}
.bg-green-600 {
  background-color: #46be8a !important;
}
.bg-green-700 {
  background-color: #36ab7a !important;
}
.bg-green-800 {
  background-color: #279566 !important;
}
.bg-light-green-100 {
  background-color: #f1f7ea !important;
}
.bg-light-green-200 {
  background-color: #e0ecd1 !important;
}
.bg-light-green-300 {
  background-color: #cadfb1 !important;
}
.bg-light-green-400 {
  background-color: #bad896 !important;
}
.bg-light-green-500 {
  background-color: #acd57c !important;
}
.bg-light-green-600 {
  background-color: #9ece67 !important;
}
.bg-light-green-700 {
  background-color: #83b944 !important;
}
.bg-light-green-800 {
  background-color: #70a532 !important;
}
.bg-yellow-100 {
  background-color: #fffae7 !important;
}
.bg-yellow-200 {
  background-color: #f9eec1 !important;
}
.bg-yellow-300 {
  background-color: #f6e7a9 !important;
}
.bg-yellow-400 {
  background-color: #f8e59b !important;
}
.bg-yellow-500 {
  background-color: #f7e083 !important;
}
.bg-yellow-600 {
  background-color: #f7da64 !important;
}
.bg-yellow-700 {
  background-color: #f9cd48 !important;
}
.bg-yellow-800 {
  background-color: #fbc02d !important;
}
.bg-orange-100 {
  background-color: #fff3e6 !important;
}
.bg-orange-200 {
  background-color: #ffddb9 !important;
}
.bg-orange-300 {
  background-color: #fbce9d !important;
}
.bg-orange-400 {
  background-color: #f6be80 !important;
}
.bg-orange-500 {
  background-color: #f4b066 !important;
}
.bg-orange-600 {
  background-color: #f2a654 !important;
}
.bg-orange-700 {
  background-color: #ec9940 !important;
}
.bg-orange-800 {
  background-color: #e98f2e !important;
}
.bg-brown-100 {
  background-color: #fae6df !important;
}
.bg-brown-200 {
  background-color: #e2bdaf !important;
}
.bg-brown-300 {
  background-color: #d3aa9c !important;
}
.bg-brown-400 {
  background-color: #b98e7e !important;
}
.bg-brown-500 {
  background-color: #a17768 !important;
}
.bg-brown-600 {
  background-color: #8d6658 !important;
}
.bg-brown-700 {
  background-color: #7d5b4f !important;
}
.bg-brown-800 {
  background-color: #715146 !important;
}
.bg-grey-100 {
  background-color: #fafafa !important;
}
.bg-grey-200 {
  background-color: #eee !important;
}
.bg-grey-300 {
  background-color: #e0e0e0 !important;
}
.bg-grey-400 {
  background-color: #bdbdbd !important;
}
.bg-grey-500 {
  background-color: #9e9e9e !important;
}
.bg-grey-600 {
  background-color: #757575 !important;
}
.bg-grey-700 {
  background-color: #616161 !important;
}
.bg-grey-800 {
  background-color: #424242 !important;
}
.bg-blue-grey-100 {
  background-color: #f3f7f9 !important;
}
.bg-blue-grey-200 {
  background-color: #e4eaec !important;
}
.bg-blue-grey-300 {
  background-color: #ccd5db !important;
}
.bg-blue-grey-400 {
  background-color: #a3afb7 !important;
}
.bg-blue-grey-500 {
  background-color: #76838f !important;
}
.bg-blue-grey-600 {
  background-color: #526069 !important;
}
.bg-blue-grey-700 {
  background-color: #37474f !important;
}
.bg-blue-grey-800 {
  background-color: #263238 !important;
}
