/* Img spirits.css */

/* Course images
-----------------------------------------------------------------------------------------*/

.course-bg-img {
  overflow: hidden;
  position: relative;
}

.course-bg-img:after {
  content: ' ';
  background-color: inherit;
  position: absolute;
  z-index: 0;
  right: -40px;
  bottom: -50px;
  top: -30px;
  transform: rotate(15deg);
  background-size: cover;
}

@media (min-width: 750px) {
  .course-bg-img:after {
    width: 250px;
  }
}
/*
@media (min-width:1200px) {
    .course-bg-img:after {
        width: 350px;
    }
} */

.course-bg-img > * {
  z-index: 1;
}

.course-bg-img:after {
  opacity: 0.9;
}

.course-bg-img._cfa-1::before {
  background-image: url('../../img/pz-class-v2/course-img/cert/cfa-1.jpg');
}

.course-bg-img._cfa-1::after {
  /* background-image: linear-gradient(135deg, rgba(67, 230, 149, 1) 0%, rgba(59, 178, 184, 1) 100%); */
  background-color: #17b3a3 !important;
  background-color: #009688 !important;
  background-color: rgb(34, 179, 131) !important;
}

.course-bg-img._cfa-2::before {
  background-image: url('../../img/pz-class-v2/course-img/cert/cfa-2.jpg');
}

.course-bg-img._cfa-2::after {
  /* background-image: linear-gradient(135deg, rgba(53, 222, 201, 1) 0%, rgba(21, 129, 144, 1) 100%); */
  background-color: #08a7dc !important;
  background-color: #00838f !important;
  background-color: #09a6ac !important;
}

.course-bg-img._cfa-3::before {
  background-image: url('../../img/pz-class-v2/course-img/cert/cfa-3.jpg');
}

.course-bg-img._cfa-3::after {
  /* background-image: linear-gradient(135deg, rgba(23, 234, 217, 1) 0%, rgba(96, 120, 234, 1) 100%); */
  background-color: #287efe !important;
  background-color: #01579b !important;
  background-color: #007377 !important;
}

.course-bg-img._frm-1::before {
  background-image: url('../../img/pz-class-v2/course-img/cert/frm-1.jpg');
}

.course-bg-img._frm-1::after {
  /* background-image: linear-gradient(135deg, rgba(252, 227, 138, 1) 0%, rgba(243, 129, 129, 1) 100%); */
  background-color: #ffa436 !important;
  background-color: #e56619 !important;
  background-color: #e7843f !important;
}

.course-bg-img._frm-2::before {
  background-image: url('../../img/pz-class-v2/course-img/cert/frm-2.jpg');
}

.course-bg-img._frm-2::after {
  /* background-image: linear-gradient(135deg, rgba(225, 118, 118, 1) 0%, rgba(245, 78, 162, 1) 100%); */
  background-color: #ff4a4a !important;
  background-color: #d32f2f !important;
  background-color: #d84949 !important;
}

.course-bg-img._cpa-1::before {
  background-image: url('../../img/pz-class-v2/course-img/cert/frm-2.jpg');
}

.course-bg-img._cpa-1::after {
  /* background-image: linear-gradient(135deg, rgba(240, 48, 193, 1) 0%, rgba(96, 148, 234, 1) 100%); */
  background-color: #854eff !important;
  background-color: #4a148c !important;
  background-color: #673e98 !important;
}

/* Cert */

.course-bg._cfa-1 {
  background-color: #17b3a3 !important;
  background-color: #009688 !important;
  background-color: rgb(34, 179, 131) !important;
}

.course-bg._cfa-2 {
  background-color: #08a7dc !important;
  background-color: #00838f !important;
  background-color: #09a6ac !important;
}

.course-bg._cfa-3 {
  background-color: #287efe !important;
  background-color: #01579b !important;
  background-color: #007377 !important;
}

.course-bg._frm-1 {
  background-color: #ffa436 !important;
  background-color: #e56619 !important;
  background-color: #e7843f !important;
}

.course-bg._frm-2 {
  background-color: #ff4a4a !important;
  background-color: #d32f2f !important;
  background-color: #d84949 !important;
}

.course-bg._cpa-1 {
  background-color: #854eff !important;
  background-color: #4a148c !important;
  background-color: #673e98 !important;
}

.course-bg._small-1 {
  background-color: #6d4c41 !important;
  background-color: #5d4037 !important;
  /* background-color: #fff !important; */
}

.course-bg._431_common{
  background-color: #9e1068 !important;
}


.course-bg-light-1._cfa-1 {
  background-color: rgba(34, 179, 131, 0.075) !important;
}

.course-bg-light-1._cfa-2 {
  background-color: rgba(9, 166, 172, 0.075) !important;
}

.course-bg-light-1._cfa-3 {
  background-color: rgba(0, 115, 119, 0.05) !important;
}

.course-bg-light-1._frm-1 {
  background-color: rgba(231, 132, 63, 0.05) !important;
}

.course-bg-light-1._frm-2 {
  background-color: rgba(216, 73, 73, 0.05) !important;
}

.course-bg-light-1._cpa-1 {
  background-color: rgba(103, 62, 152, 0.05) !important;
}

.course-bg[class*='_cfa'] .btn-outline-info,
.course-bg[class*='_frm'] .btn-outline-info,
.course-bg[class*='_cpa'] .btn-outline-info,
.course-bg[class*='_smll'] .btn-outline-info,
.course-bg[class*='_431'] .btn-outline-info {

  display: none !important;
}

/* .course-bg[class*="_free"] {
    background-color: #fff !important;
}

.course-bg[class*="_free"] .text-white {
    background-color: #fff !important;
    color: #212121 !important;
}

.course-bg[class*="_free"] .nav-link {
    color: #6c757d !important;
}

.course-bg[class*="_free"] .btn-outline-light {
    display: none !important;
} */

/* @media(max-width: 576px) {
    .cert-course .card._cfa-1 {
        border-top: solid 6px rgb(34, 179, 131) !important;
    }
    .cert-course .card._cfa-2 {
        border-top: solid 6px #09A6AC !important;
    }
    .cert-course .card._cfa-3 {
        border-top: solid 6px #007377 !important;
    }
    .cert-course .card._frm-1 {
        border-top: solid 6px #E7843F !important;
    }
    .cert-course .card._frm-2 {
        border-top: solid 6px #D84949 !important;
    }
    .cert-course .card._cpa-1 {
        border-top: solid 6px #673E98 !important;
    }
} */

.course-border._cfa-1 {
  border-color: rgb(34, 179, 131) !important;
}

.course-border._cfa-2 {
  border-color: #09a6ac !important;
}

.course-border._cfa-3 {
  border-color: #007377 !important;
}

.course-border._frm-1 {
  border-color: #e7843f !important;
}

.course-border._frm-2 {
  border-color: #d84949 !important;
}

.course-border._cpa-1 {
  border-color: #673e98 !important;
}

.course-border._431_common{
  border-color: #9e1068 !important;
}

.course-border {
  border-style: solid;
  border-width: 0;
}
.course-border-top {
  border-top-width: 4px !important;
}
.course-border-left {
  border-left-width: 6px !important;
}

.course-color._cfa-1 {
  color: rgb(34, 179, 131) !important;
}

.course-color._cfa-2 {
  color: #09a6ac !important;
}

.course-color._cfa-3 {
  color: #007377 !important;
}

.course-color._frm-1 {
  color: #e7843f !important;
}

.course-color._frm-2 {
  color: #d84949 !important;
}

.course-color._cpa-1 {
  color: #673e98 !important;
}

.course-color._small-1 {
  color: #4e342e !important;
}

.course-color._431_common{
  color: #9e1068 !important;
}

.course-img {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-position: center;
  display: inline-block;
}
.course-img._lg {
  width: 48px;
  height: 48px;
}
.course-img._2x {
  width: 70px;
  height: 70px;
}

.course-img._3x {
  width: 96px;
  height: 96px;
}

/* Small */

.course-img._cfa-1 {
  background-image: url('../../img/pz-class-v2/course-img/cert/cfa-1.svg');
}

.course-img._cfa-2 {
  background-image: url('../../img/pz-class-v2/course-img/cert/cfa-2.svg');
}

.course-img._cfa-3 {
  background-image: url('../../img/pz-class-v2/course-img/cert/cfa-3.svg');
}

.course-img._frm-1 {
  background-image: url('../../img/pz-class-v2/course-img/cert/frm-1.svg');
}

.course-img._frm-2 {
  background-image: url('../../img/pz-class-v2/course-img/cert/frm-2.svg');
}
.course-img._cpa-1 {
  background-image: url('../../img/pz-class-v2/course-img/cert/cpa.svg');
}

.course-img._small-1 {
  background-image: url('../../img/pz-class-v2/course-img/small/mdl_vba1_www.png');
}

.course-img._free-1 {
  background-image: url('../../img/pz-class-v2/course-img/free/free_icon.svg');
}

.course-img._free-2 {
  background-image: url('../../img/pz-class-v2/course-img/free/pinzhibo_icon.svg');
}

.course-icon {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-position: center;
  display: inline-block;
}

.course-icon._2x {
  width: 72px;
  height: 72px;
}

.course-icon._3x {
  width: 96px;
  height: 96px;
}

.course-icon._cfa-1 {
  background-image: url('../../img/pz-class-v2/course-img/cert/cfa-1-64x64.svg');
}

.course-icon._cfa-2 {
  background-image: url('../../img/pz-class-v2/course-img/cert/cfa-2-64x64.svg');
}

.course-icon._cfa-3 {
  background-image: url('../../img/pz-class-v2/course-img/cert/cfa-3-64x64.svg');
}

.course-icon._frm-1 {
  background-image: url('../../img/pz-class-v2/course-img/cert/frm-1-64x64.svg');
}

.course-icon._frm-2 {
  background-image: url('../../img/pz-class-v2/course-img/cert/frm-2-64x64.svg');
}
.course-icon._cpa-1 {
  background-image: url('../../img/pz-class-v2/course-img/cert/cpa-1-64x64.svg');
}
.course-icon._small-1 {
  background-image: url('../../img/pz-class-v2/course-img/small/small-1-64x64.svg');
}

.course-icon._free-1 {
  background-image: url('../../img/pz-class-v2/course-img/free/pinzhibo_icon.svg');
}

.course-icon._free-2 {
  background-image: url('../../img/pz-class-v2/course-img/free/free_icon.svg');
}

.course-award-icon {
  width: 48px;
  height: 48px;
  background-size: cover;
  background-position: center;
}

.course-award-icon._lg {
  width: 72px;
  height: 72px;
}

.course-award-icon._2x {
  width: 128px;
  height: 128px;
}

.course-award-icon._v1-0 {
  background-image: url('../../img/pz-class-v2/course_level_1_sub_256x256.png');
}

.course-award-icon._v1-1 {
  background-image: url('../../img/pz-class-v2/course_level_1_256x256.png');
}

.course-award-icon._v2-0 {
  background-image: url('../../img/pz-class-v2/course_level_2_sub_256x256.png');
}

.course-award-icon._v2-1 {
  background-image: url('../../img/pz-class-v2/course_level_2_256x256.png');
}

.course-award-icon._v3-0 {
  background-image: url('../../img/pz-class-v2/course_level_3_sub_256x256.png');
}

.course-award-icon._v3-1 {
  background-image: url('../../img/pz-class-v2/course_level_3_256x256.png');
}

/* .course-award-icon._v0 {
    background-image: url("../../img/pz-class-v2/course_level_3_sub_256x256.png");
}

.course-award-icon._v1 {
    background-image: url("../../img/pz-class-v2/course_level_1_256x256.png");
}

.course-award-icon._v2 {
    background-image: url("../../img/pz-class-v2/course_level_2_256x256.png");
}

.course-award-icon._v3 {
    background-image: url("../../img/pz-class-v2/course_level_3_256x256.png");
} */

.course-level-color._v1 {
  color: #ea9363 !important;
}

.course-level-color._v2 {
  color: #b0c5ed !important;
}

.course-level-color._v3 {
  color: #eabe4b !important;
}

/* Book icon
-----------------------------------------------------------------------------------------*/

.book-icon {
  width: 48px;
  height: 48px;
  background-size: cover;
  background-position: center;
  background-color: #ccc;
  display: inline-block;
}
.book-icon._sm {
  width: 32px !important;
  height: 32px !important;
}
.book-icon._2x {
  width: 72px !important;
  height: 72px !important;
}
.book-icon._3x {
  width: 96px !important;
  height: 96px !important;
}
.book-icon._4x {
  width: 128px !important;
  height: 128px !important;
}
@media (min-width: 750px) {
  .book-icon._2x-md {
    width: 72px !important;
    height: 72px !important;
  }
  .book-icon._3x-md {
    width: 96px !important;
    height: 96px !important;
  }
  .book-icon._4x-md {
    width: 128px !important;
    height: 128px !important;
  }
}
@media (min-width: 992px) {
  .book-icon._2x-lg {
    width: 72px !important;
    height: 72px !important;
  }
  .book-icon._3x-lg {
    width: 96px !important;
    height: 96px !important;
  }
  .book-icon._4x-lg {
    width: 128px !important;
    height: 128px !important;
  }
}

.award-progress-circle._v0 .progress-circle-left,
.award-progress-circle._v0 .progress-circle-right,
.award-progress-bar._v0 .progress-bar {
  background-color: #f7f7f7;
  /* background-image: linear-gradient(-180deg, #f7f7f7 0%, #FF9D00 100%); */
}

.award-progress-circle._v1 .progress-circle-left,
.award-progress-circle._v1 .progress-circle-right,
.award-progress-bar._v1 .progress-bar {
  background-color: #ea9363;
  /* background-image: linear-gradient(-180deg, #EA9363 0%, #A44A24 100%); */
}

.award-progress-circle._v2 .progress-circle-left,
.award-progress-circle._v2 .progress-circle-right,
.award-progress-bar._v2 .progress-bar {
  background-color: #b0c5ed;
  /* background-image: linear-gradient(-180deg, #CDE4FF 0%, #8AAFD8 100%); */
}

.award-progress-circle._v3 .progress-circle-left,
.award-progress-circle._v3 .progress-circle-right,
.award-progress-bar._v3 .progress-bar {
  background-color: #eabe4b;
  /* background-image: linear-gradient(-180deg, #FFCA20 0%, #FF9D00 100%); */
}

.award-progress-bar .progress {
  position: relative;
  margin-right: 15px;
}
.award-progress-bar .progress-text {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  text-align: right;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
}

.award-progress-bar .progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  overflow: visible !important;
  /* text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5); */
  color: rgba(0, 0, 0, 0.5) !important;
  /* padding-left: 4px; */
  z-index: 99;
}

/* Book level
-----------------------------------------------------------------------------------------*/

.book-level {
  width: 32px;
  height: 32px;
  text-align: center;
  background-size: cover;
  background-position: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 16px;
  font-weight: 500;
}
.book-level._sm {
  width: 24px;
  height: 24px;
}

.book-level._v0 {
  background-image: url('../../img/pz-class-v2/book_level_disabled_24x24.svg');
}

.book-level._v1 {
  background-image: url('../../img/pz-class-v2/book_level_1_24x24.svg');
}

.book-level._v2 {
  background-image: url('../../img/pz-class-v2/book_level_2_24x24.svg');
}

.book-level._v3 {
  background-image: url('../../img/pz-class-v2/book_level_3_24x24.svg');
}

.book-level._v0._master {
  background-image: url('../../img/pz-class-v2/book_level_master_disabled_24x24.svg');
}

.book-level._v1._master {
  background-image: url('../../img/pz-class-v2/book_level_1_master_24x24.svg');
}

.book-level._v2._master {
  background-image: url('../../img/pz-class-v2/book_level_2_master_24x24.svg');
}

.book-level._v3._master {
  background-image: url('../../img/pz-class-v2/book_level_3_master_24x24.svg');
}

/* User photo
-----------------------------------------------------------------------------------------*/

.user-photo {
  width: 40px;
  height: 40px;
  /* border: solid 2px rgba(255, 255, 255, .5); */
  display: inline-block;
  background-color: #eee;
  background-size: cover;
  background-position: center;
  margin: 0;
}

.user-photo-sm {
  width: 32px;
  height: 32px;
  border-width: 1px;
}

.user-photo-lg {
  width: 64px;
  height: 64px;
  /* border-width: 1px; */
}

.user-photo-xl {
  width: 96px;
  height: 96px;
  /* border-width: 3px; */
}

@media (min-width: 750px) {
  .user-photo-xl {
    width: 128px;
    height: 128px;
    /* border-width: 3px; */
  }
}

/*
    答题级别：“0：No level (0 points) | 1: Attempted (0 points) | 2: Familiar(50 points) | 3: Proficient (80 points) | 4: Mastered (100 points); ”
----------------------------------------------------------------------------------------------------------------------------------------------------------*/

.buliding-level {
  width: 20px;
  height: 16px;
  margin-right: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

.buliding-level._bl0 {
  background-image: url('../../img/pz-class-v2/buliding_level_0.svg');
}

.buliding-level._bl1 {
  background-image: url('../../img/pz-class-v2/buliding_level_1.svg');
}

.buliding-level._bl2 {
  background-image: url('../../img/pz-class-v2/buliding_level_2.svg');
}

.buliding-level._bl3 {
  background-image: url('../../img/pz-class-v2/buliding_level_3.svg');
}

.buliding-level._bl4 {
  background-image: url('../../img/pz-class-v2/buliding_level_4.svg');
}

.buliding-icon {
  width: 20px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

.buliding-icon._lg {
  width: 30px;
  height: 60px;
}

.buliding-outline-icon {
  width: calc(134px * 0.3);
  height: calc(240px * 0.3);
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

.buliding-outline-icon._lg {
  width: 134px;
  height: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

.buliding-icon._b0 {
  background-image: url('../../img/pz-class-v2/buliding_0.svg');
}

.buliding-icon._b1 {
  background-image: url('../../img/pz-class-v2/buliding_1.svg');
}

.buliding-icon._b2 {
  background-image: url('../../img/pz-class-v2/buliding_2.svg');
}

.buliding-icon._b3 {
  background-image: url('../../img/pz-class-v2/buliding_3.svg');
}

.buliding-icon._b4 {
  background-image: url('../../img/pz-class-v2/buliding_4.svg');
}

.buliding-outline-icon._b0 {
  background-image: url('../../img/pz-class-v2/buliding-outline_0.svg');
}

.buliding-outline-icon._b1 {
  background-image: url('../../img/pz-class-v2/buliding-outline_1.svg');
}

.buliding-outline-icon._b2 {
  background-image: url('../../img/pz-class-v2/buliding-outline_2.svg');
}

.buliding-outline-icon._b3 {
  background-image: url('../../img/pz-class-v2/buliding-outline_3.svg');
}

.buliding-outline-icon._b4 {
  background-image: url('../../img/pz-class-v2/buliding-outline_4.svg');
}

/*
    比较 level 0: 平 | 1: 升 | 2: 降 ;
    ----------------------------------------------------------------------------------------- */

.trending-icon {
  font-size: 18px;
  margin-right: 5px;
  margin-left: 5px;
}

.trending-icon._t0 {
  color: #aaa;
}

.trending-icon._t1 {
  color: #0c6;
  transform: rotate(-90deg);
}

.trending-icon._t2 {
  color: #e60;
  transform: rotate(90deg);
}

/*
    视频状态：“0：未开始 | 1: 已播放 | 2: 播放完成; ”
-----------------------------------------------------------------------------------------*/

.video-icon {
  width: 24px;
  height: 24px;
  background-image: url('../../img/subway-sprites-video-default.svg');
  background-size: 100%;
  overflow: hidden;
  display: inline-block;
}

.video-icon._v0 {
  background-position: 0px 0;
}

.video-icon._v1 {
  background-position: 0 -24px;
}

.video-icon._v2 {
  background-position: 0 -48px;
}
